import { VISIBILITY } from '@/enums';
import { MapOptions, MapTypeStyle } from '@/types';
import { ON_POI_STYLE, OFF_POI_STYLE, OFF_POI_PARK_STYLE, ON_POI_PARK_STYLE } from './styles';

export const poiStyles = (
  existingStyles?: MapTypeStyle[],
  visibility = VISIBILITY.OFF
): MapTypeStyle[] => {
  const poiStyle = visibility === VISIBILITY.ON ? ON_POI_PARK_STYLE : OFF_POI_PARK_STYLE;
  return Array.isArray(existingStyles)
    ? [
        ...existingStyles.filter(
          (option: MapTypeStyle) =>
            option.featureType !== 'poi' && option.featureType !== 'poi.park'
        ),
        poiStyle,
      ]
    : [poiStyle];
  /*
  const optionFeatures = options?.styles?.map((style: MapTypeStyle) => style.featureType) || [];
  if (optionFeatures.includes('poi')) {
    const styles: MapTypeStyle[] =
      options?.styles?.map((style: MapTypeStyle) => {
        if (style?.featureType === 'poi') {
          return poiStyle.poi;
        }
        
      if (style?.featureType === 'poi.attraction') {
        return {
          featureType: 'poi.attraction',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        };
      }
      if (style?.featureType === 'poi.park') {
        return {
          featureType: 'poi.park',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        };
      }
      if (style?.featureType === 'poi.sports_complex') {
        return {
          featureType: 'poi.sports_complex',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        };
      }
      
        return style;
      }) || [];
    return styles;
  } else {
    return options?.styles && Array.isArray(options?.styles)
      ? [...options.styles, poiStyle.poi]
      : [poiStyle.poi];
  }
  */
};
