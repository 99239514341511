import { StreetCarIconOption } from '@/types';

export const streetCarOptions: StreetCarIconOption = {
  blue_options: {
    vertical201803: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 8, y: 32 },
    },
    horizontal201803: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 32, y: 8 },
    },
    vertical201804: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 8, y: 32 },
    },
    horizontal201804: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 32, y: 8 },
    },
  },
  green_options: {
    vertical201805: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 8, y: 32 },
    },
    horizontal201805: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 32, y: 8 },
    },
    vertical201806: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 8, y: 32 },
    },
    horizontal201806: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 32, y: 8 },
    },
  },
  grey_options: {
    vertical: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 8, y: 32 },
    },
    horizontal: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 32, y: 8 },
    },
  },
  pink_options: {
    vertical201801: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 8, y: 32 },
    },
    horizontal201801: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 32, y: 8 },
    },
    vertical201802: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 8, y: 32 },
    },
    horizontal201802: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 32, y: 8 },
    },
    vertical201807: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 8, y: 32 },
    },
    horizontal201807: {
      url: '',
      origin: { x: 0, y: 0 },
      anchor: { x: 32, y: 8 },
    },
  },
};
