import { StopStation } from '@/types';

export const sponsorLinks: { noSponsorLink: string; stops: StopStation[] } = {
  noSponsorLink: 'http://okcstreetcar.com/sponsors/sponsorships/',
  stops: [
    {
      stopName: 'East Bricktown',
      sponsorLink: '',
      stopId: 1,
      uuid: '4',
      googleLink: 'https://goo.gl/maps/BQBneCP6JqwyrSPG8',
    },
    {
      stopName: 'Broadway Avenue',
      sponsorLink: 'https://schwabmeat.com/shop',
      stopId: 10,
      uuid: '12',
      googleLink: 'https://goo.gl/maps/VDzfg3UJWtMsqzcB9',
    },
    {
      stopName: 'Ballpark',
      sponsorLink: 'https://www.sonicdrivein.com',
      stopId: 2,
      uuid: '2',
      googleLink: 'https://goo.gl/maps/oUS4J7CX1Rsamh4t6',
    },
    {
      stopName: 'Automobile Alley',
      sponsorLink: 'https://rcbbank.com',
      stopId: 11,
      uuid: '13',
      googleLink: 'https://goo.gl/maps/HDUsdGrqSbQdM7LLA',
    },
    {
      stopName: 'Santa Fe Hub',
      sponsorLink: 'https://www.ruralsourcing.com',
      stopId: 3,
      uuid: '5',
      googleLink: 'https://goo.gl/maps/LFnvCCGgunkQodUQA',
    },
    {
      stopName: 'Art Park',
      sponsorLink: 'https://oklahomacontemporary.org',
      stopId: 12,
      uuid: '14',
      googleLink: 'https://goo.gl/maps/cfNGZBSi84FjzXfK6',
    },
    {
      stopName: 'Arena',
      sponsorLink: 'https://www.riverwind.com',
      stopId: 4,
      uuid: '6',
      googleLink: 'https://goo.gl/maps/qYfJxMG9xLdGLfwNA',
    },
    {
      stopName: 'North Hudson',
      sponsorLink: 'https://www.modelousa.com/en-US/home',
      stopId: 13,
      uuid: '15',
      googleLink: 'https://goo.gl/maps/qgBMGzZDLRASRmda6',
    },
    {
      stopName: 'Scissortail Park',
      sponsorLink: 'https://integrisok.com',
      stopId: 5,
      uuid: '7',
      googleLink: 'https://goo.gl/maps/Lt6rYdjg7tBJMEPr5',
    },
    {
      stopName: 'Dewey Avenue',
      sponsorLink: 'https://www.ssmhealth.com',
      stopId: 14,
      uuid: '16',
      googleLink: 'https://goo.gl/maps/bthWuoh4MMrVzKrR9',
    },
    {
      stopName: 'Myriad Gardens',
      sponsorLink: 'https://ogeenergy.com',
      stopId: 6,
      uuid: '8',
      googleLink: 'https://goo.gl/maps/ZsJ4cqJ2pSE2hrMh9',
    },
    {
      stopName: 'Midtown',
      sponsorLink: 'https://www.thecollectiveokc.com',
      stopId: 15,
      uuid: '17',
      googleLink: 'https://goo.gl/maps/Zp6WTNaruUGoJscv8',
    },
    {
      stopName: 'Business District',
      sponsorLink: 'https://www.usfleettracking.com',
      stopId: 19,
      uuid: '21',
      googleLink: 'https://goo.gl/maps/nfskG6bEJt1bS9dA8',
    },
    {
      stopName: 'Library',
      sponsorLink: 'https://phillipsmurrah.com',
      stopId: 7,
      uuid: '9',
      googleLink: 'https://goo.gl/maps/Hr2BtfmmTju7gmNRA',
    },
    {
      stopName: 'NW 10th Street',
      sponsorLink: 'https://theoiltree.com',
      stopId: 16,
      uuid: '18',
      googleLink: 'https://goo.gl/maps/4ryL3dML62HuUzMS9',
    },
    {
      stopName: 'Century Center',
      sponsorLink: 'https://nationalcowboymuseum.org',
      stopId: 20,
      uuid: '22',
      googleLink: 'https://goo.gl/maps/xN8qZCYova9d3iyE7',
    },
    {
      stopName: 'Transit Center',
      sponsorLink: 'https://www.comfedcu.org',
      stopId: 8,
      uuid: '10',
      googleLink: 'https://goo.gl/maps/xocdvziCi2Tp3MgU8',
    },
    {
      stopName: 'Law School',
      sponsorLink: 'https://law.okcu.edu',
      stopId: 17,
      uuid: '19',
      googleLink: 'https://goo.gl/maps/bZPkoKdjWTrE62X56',
    },
    {
      stopName: 'Bricktown',
      sponsorLink: 'https://go.okstate.edu',
      stopId: 21,
      uuid: '23',
      googleLink: 'https://goo.gl/maps/2rvoWLNUXxmoN6R56',
    },
    {
      stopName: 'Federal Courthouse',
      sponsorLink: '',
      stopId: 9,
      uuid: '11',
      googleLink: 'https://goo.gl/maps/y3Vv28qpBPa8XTYx8',
    },
    {
      stopName: 'Memorial Museum',
      sponsorLink: 'https://oklahomacitynationalmemorial.org',
      stopId: 18,
      uuid: '20',
      googleLink: 'https://goo.gl/maps/HrHL8c3p4b84LWWd9',
    },
    {
      stopName: 'Mickey Mantle',
      sponsorLink: 'https://newcastlecasino.com ',
      stopId: 22,
      uuid: '24',
      googleLink: 'https://goo.gl/maps/u5syr44XR2GEETwU9',
    },
  ],
};
