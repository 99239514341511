<template>
  <g-marker
    v-if="spokieMarker"
    :address="spokieMarker.address"
    :clickable="true"
    :content="spokieMarker.content"
    :draggable="false"
    :icon="spokieMarker.icon"
    :generatedESN="spokieMarker.esn"
    :opened="spokieMarker.opened"
    :position="spokieMarker.position"
    :title="spokieMarker.title"
    :type="spokieMarker.type"
    :zIndex="100"
  >
    <template v-slot="{ marker, map }">
      <info-window v-if="spokieMarker.content" :map="map" :marker="marker">
        <div class="marker-window spokie" v-if="marker?.opened && marker.type === type">
          <div
            v-bind:class="[
              marker.content.googleLink && marker.content.ticketsLink ? 'bottom-margin' : null,
            ]"
          >
            <div class="spokies-name" v-if="marker.title">{{ marker.title }}</div>
            <div class="spokies-address" v-if="marker.address">
              {{ marker.address }}
            </div>
            <div class="spokies-google-link" v-if="marker.content.googleLink">
              <a target="_blank" :href="marker.content.googleLink">Get Directions</a>
            </div>
          </div>
        </div>
      </info-window></template
    >
  </g-marker>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import InfoWindow from '@/components/InfoWindow.vue';
import GMarker from '@/components/GMarker.vue';
import { SpokieMarker } from '@/types';
export default defineComponent({
  name: 'Spokie Marker',
  components: { GMarker, InfoWindow },
  props: {
    spokie: Object as PropType<SpokieMarker>,
  },
  setup(props) {
    const spokieMarker = ref(props.spokie);
    const type = ref(props.spokie?.type);
    return { spokieMarker, type };
  },
});
</script>

<style scoped lang="scss"></style>
