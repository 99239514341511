<template>
  <g-marker
    v-if="parkingMarker"
    :address="parkingMarker.address"
    :clickable="true"
    :content="parkingMarker.content"
    :draggable="false"
    :generatedESN="parkingMarker.esn"
    :icon="parkingMarker.icon"
    :opened="parkingMarker.opened"
    :position="parkingMarker.position"
    :title="parkingMarker.title"
    :type="parkingMarker.type"
    :zIndex="100"
  >
    <template v-slot="{ marker, map }">
      <info-window v-if="parkingMarker.content" :map="map" :marker="marker">
        <div class="marker-window parking" v-if="marker?.opened && marker.type === type">
          <div
            v-bind:class="[
              marker.content.googleLink && marker.content.ticketsLink ? 'bottom-margin' : null,
            ]"
          >
            <div class="parking-name" v-if="marker.title">{{ marker.title }}</div>
            <div class="parking-address" v-if="marker.address">
              {{ marker.address }}
            </div>
            <div class="parking-google-link" v-if="marker.content.googleLink">
              <a target="_blank" :href="marker.content.googleLink">Get Directions</a>
            </div>
          </div>
        </div>
      </info-window></template
    >
  </g-marker>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import InfoWindow from '@/components/InfoWindow.vue';
import GMarker from '@/components/GMarker.vue';
import { ParkingMarker } from '@/types';
export default defineComponent({
  name: 'Parking Marker',
  components: { GMarker, InfoWindow },
  props: {
    parking: Object as PropType<ParkingMarker>,
  },
  setup(props) {
    const parkingMarker = ref(props.parking);
    const type = ref(props.parking?.type);
    return { parkingMarker, type };
  },
});
</script>

<style scoped lang="scss"></style>
