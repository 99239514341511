import { MARKER_TYPE, POI_IDS, ROUTE_IDS, STOP_IDS } from '@/enums';
import { Boundary, DirectionsHash, LatLng, RouteClass } from '@/types';
import Kiosk_streetcar_track_overlay_source from '@/assets/kiosk-svg-v5.svg';
import { PinPoint } from '@/types/pin-point';
import poi_overlay_source from '@/assets/streetcar-poi.svg';
import { StopName } from '../types/stop-name';
import device_streetcar_track_overlay_source from '@/assets/streetcar-tracks4.svg';

export const __PROD__ = process.env.NODE_ENV === 'production';

export const __SITE_URL = process.env.VUE_APP_SITE_URL;

export const ALL_STOPS_API = `${__SITE_URL}/api/stops/all-stops.json`;

export const API_TOKEN: string = __PROD__
  ? process.env.VUE_APP_YOUR_API_TOKEN
  : process.env.VUE_APP_YOUR_TEST_API_TOKEN;

export const ARROWS = [
  {
    lng: -97.50598222017287,
    lat: 35.465402391214544,
  },
  {
    lng: -97.50600904226303,
    lat: 35.46472518066986,
  },
];

export const ASSET_CHECK_INTERVAL = 30 * 1000;

export const CANNED_MESSAGES_API = `${__SITE_URL}/api/stops/canned-messages.json`;

export const CENTER: LatLng = { lng: -97.5145, lat: 35.4705052 };

export const CHECK_PARKING_KEY = 'checkParking';

export const CHECK_SPOKIE_KEY = 'checkSpokies';

export const CHECK_STREET_KEY = 'checkStreets';

export const CHECK_TRANSIT_CENTER_KEY = 'checkTransitCenter';

export const DEFAULT_CENTER: LatLng = { lng: -97.5145, lat: 35.4705052 };

export const DEFAULT_CHECK_PARKING = false;

export const DEFAULT_CHECK_SPOKIES = false;

export const DEFAULT_CHECK_STREET = true;

export const DEFAULT_CHECK_TRANSIT_CENTER = false;

export const DEFAULT_DEVICE_STREETCAR_RAIL_TRACK_BOUNDARY: Boundary = {
  north: 35.48024,
  south: 35.4622,
  east: -97.5059,
  west: -97.52277,
};

export const DEFAULT_FALSE = false;

export const DEFAULT_KIOSK_STREETCAR_TRACK_OVERLAY = Kiosk_streetcar_track_overlay_source;

export const DEFAULT_KIOSK_STREETCAR_TRACK_BOUNDARY: Boundary = {
  north: 35.48124,
  south: 35.4599,
  east: -97.5041,
  west: -97.52527,
};

export const DEFAULT_POI_BOUNDARY: Boundary = {
  north: 35.48123,
  south: 35.4599,
  east: -97.4938,
  west: -97.53595,
};

export const DEFAULT_POI_OVERLAY = poi_overlay_source;

export const DEFAULT_SHOW_POI = false;

export const DEFAULT_SHOW_STOP_LABEL = false;

export const DEFAULT_DEVICE_STREETCAR_RAIL_TRACK_OVERLAY = device_streetcar_track_overlay_source;

export const DEFAULT_TRUE = true;

export const DEFAULT_ZOOM = 15;

export const directionsHash: DirectionsHash = {};

export const fontWeight = 'bold';

export const fontSize = '16px';

export const HORIZONTAL_ICONS: number[] = [1, 6, 7, 8, 10, 11, 12, 14, 17, 18, 19];

export const MESSAGE_CHECK_INTERVAL = 10 * 1000;

export const MIN_REASONABLE_SPEED = 4;

export const NE_BOUND = { lng: -97.50082969665527, lat: 35.48611327253733 };

export const NOT_IN_SERVICE = 'Not in Service';

export const POI_COLLECTION = [
  MARKER_TYPE.ATTRACTION,
  MARKER_TYPE.CITY,
  MARKER_TYPE.MUSEUM,
  MARKER_TYPE.SANTA_FE_DPOT,
  MARKER_TYPE.WATER_TAXI,
];

export const POI_LABEL_POSITION = {
  [`${POI_IDS.AMERICAN_BANJO_MUSEUM}`]: { x: 20, y: -12 }, // American Banjo Museum
  [`${POI_IDS.BICENTENNIAL_PARK}`]: { x: 10, y: 25 }, // Bicentennial Park
  [`${POI_IDS.BRICKTOWN_BALLPARK}`]: { x: 10, y: 28 }, // Bricktown Ballpark
  [`${POI_IDS.BRICKTOWN_CANAL}`]: { x: 10, y: 25 }, // Bricktown Canal
  [`${POI_IDS.BRICKTOWN_WATER_TAXI}`]: { x: 30, y: 30 }, // Brictown Water Taxi
  [`${POI_IDS.CAMPELL_ART_PARK}`]: { x: 50, y: 25 }, // Campbell Art Park
  [`${POI_IDS.CHESAPEAKE_ARENA}`]: { x: 40, y: 10 }, // Chesapeake Arena
  [`${POI_IDS.CITY_HALL}`]: { x: 8, y: -8 }, // City Hall
  [`${POI_IDS.CIVIC_CENTER}`]: { x: 4, y: -9 }, // Civic Center
  [`${POI_IDS.COX_CONVENTION_CENTER}`]: { x: 0, y: -10 }, // Cox Convention Center
  [`${POI_IDS.DOWNTOWN_LIBRARY}`]: { x: 0, y: 29 }, // Downtown Library
  [`${POI_IDS.FACTORY_OBSCURA}`]: { x: 45, y: -10 }, // Factory Obscura
  [`${POI_IDS.KERR_PARK}`]: { x: 45, y: 9 }, // Kerr Park
  [`${POI_IDS.MIDTOWN_MUTTS}`]: { x: 10, y: 25 }, // Midtown Mutts
  [`${POI_IDS.MUSEUM_OF_ART}`]: { x: -30, y: -10 }, // Museum of Art
  [`${POI_IDS.MYRIAD_BOTANICAL}`]: { x: 10, y: -12 }, // Myriad Botanical
  [`${POI_IDS.NATIONAL_MEMORIAL}`]: { x: -70, y: -9 }, // National Memorial
  [`${POI_IDS.OKLAHOMA_CONTEMPORARY_ARTS}`]: { x: 90, y: 10 }, // Oklahoma Contemporary Arts
  [`${POI_IDS.RED_EARTH}`]: { x: 15, y: -10 }, // Red Earth
  [`${POI_IDS.SANTA_FE_DEPOT}`]: { x: 60, y: 10 }, // Santa Fe Depot
  [`${POI_IDS.SPORTS_HALL_OF_FAME}`]: { x: 50, y: 15 }, // Sports Hall Of Fame
  [`${POI_IDS.SCISSORTAIL_PARK}`]: { x: 15, y: 25 }, // Scissortail Park
  [`${POI_IDS.THE_CRITERION}`]: { x: -28, y: 8 }, // The Criterion
  [`${POI_IDS.TOGETHER_SQUARE}`]: { x: -45, y: 8 }, // Together Square
};

export const REVENUE_ROUTES: ROUTE_IDS[] = [ROUTE_IDS.BRICKTOWN, ROUTE_IDS.DOWNTOWN];

export const ROUTES = {
  [`${ROUTE_IDS.DOWNTOWN}`]: 'Downtown Loop',
  [`${ROUTE_IDS.BRICKTOWN}`]: 'Bricktown Loop',
  [`${ROUTE_IDS.NON_REVENUE}`]: 'Non Revenue Loop',
};

export const ROUTE_CLASSES: RouteClass = {
  [`${ROUTE_IDS.DOWNTOWN}`]: 'streetcar-route-downtown',
  [`${ROUTE_IDS.BRICKTOWN}`]: 'streetcar-route-bricktown',
  [`${ROUTE_IDS.NON_REVENUE}`]: 'streetcar-route-non-revenue',
};

export const SHOW_POI_KEY = 'showPOI';

export const STOP_ICON_ARNCHOR: PinPoint = {
  [`${STOP_IDS.EAST_BRICKTOWN}`]: { x: 9, y: 9 }, // East Bricktown
  [`${STOP_IDS.BALL_PARK}`]: { x: 9, y: 9 }, // Ball Park
  [`${STOP_IDS.SANTA_FE_HUB}`]: { x: 9, y: 9 }, // Sante Fe Hub
  [`${STOP_IDS.ARENA}`]: { x: 9, y: 9 }, // Arena
  [`${STOP_IDS.SCISSORTAIL_PARK}`]: { x: 9, y: 9 }, // Scissortail Park
  [`${STOP_IDS.MYRIAD_GARDENS}`]: { x: 9, y: 9 }, // Myriad Gardens
  [`${STOP_IDS.LIBRARY}`]: { x: 9, y: 9 }, // Library
  [`${STOP_IDS.TRANSIT_CENTER}`]: { x: 9, y: 9 }, // Transit Center
  [`${STOP_IDS.FEDERAL_COURTHOUSE}`]: { x: 9, y: 9 }, // Federal Courthouse
  [`${STOP_IDS.BROADWAY_AVENUE}`]: { x: 10, y: 9 }, // Broadway Avenue
  [`${STOP_IDS.AUTOMOBILE_ALLEY}`]: { x: 10, y: 9 }, // Automobile Alley
  [`${STOP_IDS.ART_PARK}`]: { x: 10, y: 9 }, // Art Park
  [`${STOP_IDS.NORTH_HUDSON}`]: { x: 9, y: 9 }, // North Hudson
  [`${STOP_IDS.DEWEY_AVENUE}`]: { x: 6, y: 9 }, // Dewey Avenue
  [`${STOP_IDS.MIDTOWN}`]: { x: 9, y: 10 }, // Midtown
  [`${STOP_IDS.NW_10TH_STREET}`]: { x: 9, y: 10 }, // NW 10th Street
  [`${STOP_IDS.LAW_SCHOOL}`]: { x: 9, y: 9 }, // Law School
  [`${STOP_IDS.MEMORIAL_MUSEUM}`]: { x: 9, y: 17 }, // Memorial Museum
  [`${STOP_IDS.BUSINESS_DISTRICT}`]: { x: 6, y: 9 }, // Business District
  [`${STOP_IDS.CENTRURY_CENTER}`]: { x: 9, y: 10 }, // Century Center
  [`${STOP_IDS.BRICKTOWN}`]: { x: 9, y: 10 }, // Bricktown
  [`${STOP_IDS.MICKEY_MANTLE}`]: { x: 9, y: 10 }, // Mickey Mantle
};

export const STOP_ICON_ORIGIN: PinPoint = {
  [`${STOP_IDS.EAST_BRICKTOWN}`]: { x: 0, y: 0 }, // East Bricktown
  [`${STOP_IDS.BALL_PARK}`]: { x: 0, y: 0 }, // Ball Park
  [`${STOP_IDS.SANTA_FE_HUB}`]: { x: 0, y: 0 }, // Sante Fe Hub
  [`${STOP_IDS.ARENA}`]: { x: 0, y: 0 }, // Arena
  [`${STOP_IDS.SCISSORTAIL_PARK}`]: { x: 0, y: 0 }, // Scissortail Park
  [`${STOP_IDS.MYRIAD_GARDENS}`]: { x: 0, y: 0 }, // Myriad Gardens
  [`${STOP_IDS.LIBRARY}`]: { x: 0, y: 0 }, // Library
  [`${STOP_IDS.TRANSIT_CENTER}`]: { x: 0, y: 0 }, // Transit Center
  [`${STOP_IDS.FEDERAL_COURTHOUSE}`]: { x: 0, y: 0 }, // Federal Courthouse
  [`${STOP_IDS.BROADWAY_AVENUE}`]: { x: 0, y: 0 }, // Broadway Avenue
  [`${STOP_IDS.AUTOMOBILE_ALLEY}`]: { x: 0, y: 0 }, // Automobile Alley
  [`${STOP_IDS.ART_PARK}`]: { x: 0, y: 0 }, // Art Park
  [`${STOP_IDS.NORTH_HUDSON}`]: { x: 0, y: 0 }, // North Hudson
  [`${STOP_IDS.DEWEY_AVENUE}`]: { x: 0, y: 0 }, // Dewey Avenue
  [`${STOP_IDS.MIDTOWN}`]: { x: 0, y: 0 }, // Midtown
  [`${STOP_IDS.NW_10TH_STREET}`]: { x: 0, y: 0 }, // NW 10th Street
  [`${STOP_IDS.LAW_SCHOOL}`]: { x: 0, y: 0 }, // Law School
  [`${STOP_IDS.MEMORIAL_MUSEUM}`]: { x: 0, y: 0 }, // Memorial Museum
  [`${STOP_IDS.BUSINESS_DISTRICT}`]: { x: 0, y: 0 }, // Business District
  [`${STOP_IDS.CENTRURY_CENTER}`]: { x: 0, y: 0 }, // Century Center
  [`${STOP_IDS.BRICKTOWN}`]: { x: 0, y: 0 }, // Bricktown
  [`${STOP_IDS.MICKEY_MANTLE}`]: { x: 0, y: 0 }, // Mickey Mantle
};

export const STOP_LABEL_KEY = 'stopLabels';

export const STOP_LABEL_POSITION = {
  [`${STOP_IDS.ARENA}`]: { x: 20, y: -10 }, // Arena
  [`${STOP_IDS.ART_PARK}`]: { x: 50, y: 8 }, // Art Park
  [`${STOP_IDS.AUTOMOBILE_ALLEY}`]: { x: 85, y: 10 }, // Automobile Alley
  [`${STOP_IDS.BALL_PARK}`]: { x: 25, y: -10 }, // Ball Park
  [`${STOP_IDS.BRICKTOWN}`]: { x: 5, y: 25 }, // Bricktown
  [`${STOP_IDS.BROADWAY_AVENUE}`]: { x: 85, y: 10 }, // Broadway Avenue
  [`${STOP_IDS.BUSINESS_DISTRICT}`]: { x: 80, y: 10 }, // Business District
  [`${STOP_IDS.CENTRURY_CENTER}`]: { x: 33, y: 25 }, // Century Center
  [`${STOP_IDS.DEWEY_AVENUE}`]: { x: -53, y: 8 }, // Dewey Avenue
  [`${STOP_IDS.EAST_BRICKTOWN}`]: { x: -60, y: 12 }, // East Bricktown
  [`${STOP_IDS.FEDERAL_COURTHOUSE}`]: { x: 80, y: 25 }, // Federal Courthouse
  [`${STOP_IDS.LAW_SCHOOL}`]: { x: -45, y: 8 }, // Law School
  [`${STOP_IDS.LIBRARY}`]: { x: 50, y: 8 }, // Library
  [`${STOP_IDS.MEMORIAL_MUSEUM}`]: { x: -65, y: -1 }, // Memorial Museum
  [`${STOP_IDS.MICKEY_MANTLE}`]: { x: 15, y: 25 }, // Mickey Mantle
  [`${STOP_IDS.MIDTOWN}`]: { x: 0, y: 25 }, // Midtown
  [`${STOP_IDS.MYRIAD_GARDENS}`]: { x: 85, y: 10 }, // Myriad Gardens
  [`${STOP_IDS.NORTH_HUDSON}`]: { x: 0, y: -6 }, // North Hudson
  [`${STOP_IDS.NW_10TH_STREET}`]: { x: -30, y: 25 }, // NW 10th Street
  [`${STOP_IDS.SANTA_FE_HUB}`]: { x: 30, y: -10 }, // Sante Fe Hub
  [`${STOP_IDS.SCISSORTAIL_PARK}`]: { x: -35, y: -10 }, // Scissortail Park
  [`${STOP_IDS.TRANSIT_CENTER}`]: { x: 70, y: 12 }, // Transit Center
};

export const STOPS: StopName = {
  [`${STOP_IDS.EAST_BRICKTOWN}`]: 'East Bricktown',
  [`${STOP_IDS.BALL_PARK}`]: 'Ball Park',
  [`${STOP_IDS.SANTA_FE_HUB}`]: 'Santa Fe Hub',
  [`${STOP_IDS.ARENA}`]: 'Arena',
  [`${STOP_IDS.SCISSORTAIL_PARK}`]: 'Scissortail Park',
  [`${STOP_IDS.MYRIAD_GARDENS}`]: 'Myriad Gardens',
  [`${STOP_IDS.LIBRARY}`]: 'Library',
  [`${STOP_IDS.TRANSIT_CENTER}`]: 'Transit Center',
  [`${STOP_IDS.FEDERAL_COURTHOUSE}`]: 'Federal Courthouse',
  [`${STOP_IDS.BROADWAY_AVENUE}`]: 'Broadway Avenue',
  [`${STOP_IDS.AUTOMOBILE_ALLEY}`]: 'Automobile Alley',
  [`${STOP_IDS.ART_PARK}`]: 'Art park',
  [`${STOP_IDS.NORTH_HUDSON}`]: 'North Hudson',
  [`${STOP_IDS.DEWEY_AVENUE}`]: 'Dewey Avenue',
  [`${STOP_IDS.MIDTOWN}`]: 'Midtown',
  [`${STOP_IDS.NW_10TH_STREET}`]: 'NW 10th Street',
  [`${STOP_IDS.LAW_SCHOOL}`]: 'Law School',
  [`${STOP_IDS.MEMORIAL_MUSEUM}`]: 'Memorial Museum',
  [`${STOP_IDS.BUSINESS_DISTRICT}`]: 'Business District',
  [`${STOP_IDS.CENTRURY_CENTER}`]: 'Century Center',
  [`${STOP_IDS.BRICKTOWN}`]: 'Bricktown',
  [`${STOP_IDS.MICKEY_MANTLE}`]: 'Mickey Mantle',
};

export const STREET_CAR_SOUTH_LATITUDE_BOUND = 35.46196328151039;

export const STROKE_WEIGHT = 5;

export const SW_BOUND = { lng: -97.53001213073729, lat: 35.454657441661645 };

export const TICKET_LINK =
  'https://tokentransit.com/app?a=embark&utm_medium=web&utm_source=agency_website';

// Because token expires every 24 hours, we just update it after 23 hours
export const TOKEN_VALID_TIME = 23 * 60 * 60 * 1000; // 23 hours or 1000ms * 60s * 60m * 23 hrs

export const US_FLEET_ETA = 'https://stream.transit.usft.com/eta';

export const US_FLEET_LOCATION = 'https://stream.transit.usft.com/location';

export const USER_LOCATION_INTERVAL_TIME = __PROD__ ? 3000 : 30000;

export const VERTICAL_ICONS: number[] = [2, 3, 4, 5, 9, 13, 15, 16, 20, 21, 22];

export const ZOOM = 16;
