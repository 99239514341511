<template>
  <div class="map-controls">
    <div class="map-controls-header-wrapper">
      <div class="map-controls-header-top">
        <div class="map-controls-streetcar-logo-wrapper">
          <a target="_blank" href="https://okcstreetcar.com"
            ><img class="map-controls-streetcar-logo" :src="okc_streetcar" alt="Street Cars"
          /></a>
        </div>
        <div class="map-controls-mobile-menu-wrapper hide-on-desktop">
          <transition name="show-hide" enter-active-class="animated zoomIn">
            <img
              class="map-control-mobile-menu"
              v-if="show_mobile_legend"
              @click="show_mobile_legend = !show_mobile_legend"
              :src="mobile_close_menu_button"
            />
          </transition>
          <transition name="show-hide" enter-active-class="animated zoomIn">
            <img
              class="map-control-mobile-menu"
              v-if="!show_mobile_legend"
              @click="show_mobile_legend = !show_mobile_legend"
              :src="mobile_open_menu_button"
            />
          </transition>
          <!-- <transition name="show-hide" enter-active-class="animated zoomIn">
            <img
              class="map-control-mobile-menu"
              v-if="!show_mobile_legend && !alertMessage"
              @click="show_mobile_legend = !show_mobile_legend"
              :src="mobile_open_menu_button"
            />
          </transition>
          <transition name="show-hide" enter-active-class="animated zoomIn">
            <img
              class="map-control-mobile-menu menu-with-alert"
              v-if="!show_mobile_legend && alertMessage"
              @click="show_mobile_legend = !show_mobile_legend"
              :src="mobile_open_menu_button_with_alert"
            />
          </transition> -->
        </div>
      </div>
    </div>

    <div v-bind:class="{ 'hide-mobile-legend': !show_mobile_legend }" class="legend-content">
      <div class="map-controls-description-wrapper">
        <div class="map-controls-description">
          Tap the icons on the map for arrival times and transit details.
        </div>
      </div>
      <div v-if="alertMessage?.usFleetAlertMessage" class="control-alert-wrapper">
        <div class="map-controls-alert-message" v-if="alertMessage?.usFleetAlertMessage">
          {{ alertMessage.usFleetAlertMessage }}
        </div>
      </div>
      <div v-else class="control-alert-wrapper">
        <div class="map-controls-alert-message" v-if="alertMessage?.downtownAlertMessage">
          Downtown: {{ alertMessage.downtownAlertMessage }}
        </div>
        <div class="map-controls-alert-message" v-if="alertMessage?.bricktownAlertMessage">
          Bricktown: {{ alertMessage.bricktownAlertMessage }}
        </div>
      </div>
      <div class="map-controls-item streets-items">
        <div class="streets">Streets</div>
        <label class="checkmark-wrapper">
          <input type="checkbox" v-model="streets" />
          <span class="checkmark"></span>
        </label>
      </div>

      <div class="map-controls-item parking-items">
        <div class="parking">Parking</div>
        <label class="checkmark-wrapper">
          <input type="checkbox" v-model="parking" />
          <span class="checkmark"></span>
        </label>
      </div>
      <!-- <div class="map-controls-item spokies-items">
        <div class="spokies">Spokies</div>
        <label class="checkmark-wrapper">
          <input type="checkbox" v-model="spokies" />
          <span class="checkmark"></span>
        </label>
      </div> -->
      <!-- <div class="map-controls-item transit-center-items">
        <div class="transit-center">Transit Center</div>
        <label class="checkmark-wrapper">
          <input type="checkbox" v-model="transitCenter" />
          <span class="checkmark"></span>
        </label>
      </div> -->
      <div class="map-controls-item stop-labels-items">
        <div class="stop-labels">Stop Labels</div>
        <label class="checkmark-wrapper">
          <input type="checkbox" v-model="stop_labels" />
          <span class="checkmark"></span>
        </label>
      </div>

      <div class="map-controls-item point-of-interest-items">
        <div class="point-of-interest">Points Of Interest</div>
        <label class="checkmark-wrapper">
          <input type="checkbox" v-model="showPOI" />
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="map-controls-footer">
        <div class="map-controls-footer-links">
          <a
            class="map-controls-footer-links-schedules"
            target="_blank"
            href="http://okcstreetcar.com/schedule/"
            >Streetcar Schedules</a
          >
          <a
            class="map-controls-footer-links-tickets"
            target="_blank"
            href="https://tokentransit.com/app?a=embark&utm_medium=web&utm_source=agency_website"
            >Get Tickets</a
          >
        </div>
        <div class="text-logo-wrapper" v-if="us_fleet_logo">
          <span>Tracked by: </span
          ><a target="_blank" href="https://www.usfleettracking.com" class="usfleet-logo-link"
            ><img class="usfleet-logo" :src="us_fleet_logo"
          /></a>
        </div>
        <div class="text-beta-wrapper" v-if="beta_release">
          <span
            >We welcome your
            <a target="_blank" href="http://okcstreetcar.com/contact">feedback</a>.</span
          >
        </div>
      </div>
    </div>
  </div>
  <!--map-controls-->
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import alertBubble from '../assets/icons/map-controls/alert-bubble.svg';
import mobileCloseMenuButton from '../assets/icons/map-controls/mobile-close-menu-button.svg';
import mobileOpenMenuButton from '../assets/icons/map-controls/mobile-open-menu-button.svg';
import mobileOpenMenuButtonWithAlert from '../assets/icons/map-controls/mobile-open-menu-button-with-alert.svg';
import okcStreetcar from '../assets/icons/map-controls/okc-streetcar-logo.svg';
import usFleetLogo from '../assets/icons/map-controls/usfleet-logo.svg';
import {
  CHECK_STREET_KEY,
  hideRoadsAndLabels,
  showRoadsAndLabels,
  TOGGLE_MOBILE_LEGEND,
  TOGGLE_STREETS,
  toggleCheckStreets,
  toggleCheckParking,
  toggleCheckSpokies,
  STOP_LABEL_KEY,
  TOGGLE_STOP_LABELS,
  toggleStopLabel,
  toggleShowPOI,
  toggleCheckTransitCenter,
} from '@/utils';
export default defineComponent({
  name: 'Map Control',
  setup(props) {
    const store = useStore();

    // state
    const alert_bubble = ref(alertBubble);
    const alertMessage = computed(() => store.state.alertMessage);
    const beta_release = ref(true);
    const mobile_close_menu_button = ref(mobileCloseMenuButton);
    const mobile_open_menu_button = ref(mobileOpenMenuButton);
    const mobile_open_menu_button_with_alert = ref(mobileOpenMenuButtonWithAlert);
    const okc_streetcar = ref(okcStreetcar);
    const us_fleet_logo = ref(usFleetLogo);

    // computed properties
    const parking = computed({
      get: () => store.state.controls.checkParking,
      set: (value: boolean) => store.dispatch(toggleCheckParking, value),
    });

    const show_mobile_legend = computed({
      get: () => store.state.openMobileLegend,
      set: (value: boolean) => store.commit(TOGGLE_MOBILE_LEGEND, value),
    });

    const showPOI = computed({
      get: () => store.state.controls.showPOI,
      set: (value: boolean) => store.dispatch(toggleShowPOI, value),
    });

    const spokies = computed({
      get: () => store.state.controls.checkSpokies,
      set: (value: boolean) => {
        store.dispatch(toggleCheckSpokies, value);
      },
    });

    const stop_labels = computed({
      get: () => store.state.controls.showStopLabel,
      set: (value: boolean) => store.dispatch(toggleStopLabel, value),
    });

    const streets = computed({
      get: () => store.state.controls.checkStreets,
      set: (value: boolean) => store.dispatch(toggleCheckStreets, value),
    });

    const transitCenter = computed({
      get: () => store.state.controls.checkTransitCenter,
      set: (value: boolean) => store.dispatch(toggleCheckTransitCenter, value),
    });

    return {
      alert_bubble,
      alertMessage,
      beta_release,
      mobile_close_menu_button,
      mobile_open_menu_button,
      mobile_open_menu_button_with_alert,
      okc_streetcar,
      parking,
      us_fleet_logo,
      show_mobile_legend,
      showPOI,
      spokies,
      stop_labels,
      streets,
      transitCenter,
    };
  },
});
</script>

<style scoped lang="scss"></style>
