<template>
  <div class="poi-overlay"></div>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, onMounted, PropType, ref, watch } from 'vue';
import { DEFAULT_POI_BOUNDARY, DEFAULT_POI_OVERLAY, UPDATE_POI_OVERLAY } from '@/utils';
import { Boundary, State } from '@/types';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'POIOverlay',
  props: {
    image: String,
    imageBounds: {
      type: Object as PropType<Boundary>,
    },
  },
  setup(props) {
    const store = useStore();
    const google = computed(() => store.getters.google);
    const map = computed(() => store.getters.map);
    const source: string = props.image || DEFAULT_POI_OVERLAY;
    const imageBounds = props.imageBounds || DEFAULT_POI_BOUNDARY;
    const overlay = ref();

    onMounted(async () => {
      overlay.value = new google.value.maps.GroundOverlay(source, imageBounds);
      overlay.value.setMap(map.value);
    });

    watch(map, () => {
      overlay.value.setMap(map.value);
      store.commit(UPDATE_POI_OVERLAY, overlay.value);
    });
  },
});
</script>

<style scoped lang="scss"></style>
