import { uuid } from 'vue-uuid';
import { MARKER_TYPE, ORIENTATION, ROUTE_IDS } from '@/enums';
import {
  AssetData,
  Content,
  Icon,
  MapOptions,
  MapTypeStyle,
  MarkerType,
  StopMarker,
  StreetCar,
  StreetCarMarker,
} from '@/types';
import {
  CENTER,
  DEFAULT_CHECK_STREET,
  DEFAULT_SHOW_POI,
  HORIZONTAL_ICONS,
  STOP_LABEL_POSITION,
  NOT_IN_SERVICE,
  POI_LABEL_POSITION,
  REVENUE_ROUTES,
  ROUTES,
  ROUTE_CLASSES,
  STOP_ICON_ARNCHOR,
  STOP_ICON_ORIGIN,
  VERTICAL_ICONS,
  __PROD__,
} from './constants';
import { icons } from './icons';

export const formatLabel = (label: string) => {
  const result = label
    .split(' ')
    .splice(1)
    .join(' ');
  return result ? { text: result } : { text: label };
};

export const combineStyles = (options?: MapOptions, styles?: MapTypeStyle[]): MapTypeStyle[] => {
  const features = styles?.map((style: MapTypeStyle) => style.featureType) || [];
  //const elements = styles?.map((style: MapTypeStyle) => style.elementType) || [];
  return [
    ...(options?.styles?.filter(
      (optionStyle: MapTypeStyle) => !features.includes(optionStyle.featureType)
    ) || []),
    ...(styles || []),
  ];
};

export const createStopMarker = (stop: StopMarker, content: Content, icon: Icon): StopMarker => {
  const position =
    stop.latitude && stop.longitude
      ? {
          lat: stop.latitude,
          lng: stop.longitude,
        }
      : CENTER;
  return {
    ...stop,
    content,
    esn: uuid.v4(),
    icon,
    label: formatLabel(stop.name),
    position,
    title: formatLabel(stop.name).text,
    type: MARKER_TYPE.STOP,
  };
};

export const formatters = {
  plain: (e: MessageEvent) => e.data,
  json: (e: MessageEvent) => JSON.parse(e.data),
};

export const getRouteName = (id: number) => {
  if (id && REVENUE_ROUTES.indexOf(id) != -1) {
    return ROUTES[id];
  }
  return NOT_IN_SERVICE;
};

export const getStreetCarAsset = (
  stateData: AssetData[] | undefined,
  trolley: StreetCar | StreetCarMarker
) => {
  return stateData?.find(
    (asset: AssetData) => parseInt(asset?.customId || '') === trolley.streetcar
  );
};

export const getStreetCarNumber = (streetcar: number, lastDigits = 3) => {
  if (streetcar) {
    const str = String(streetcar);
    return str.substr(str.length - lastDigits);
  }
};

export const handleError = (err: Error) => {
  if (!__PROD__) {
    //eslint-disable-next-line
    console.log(err);
  } else {
    //eslint-disable-next-line
    console.log('Encountered a problem...');
  }
};

export const getPoiIcon = (type: MarkerType) => {
  if (type === MARKER_TYPE.ATTRACTION) {
    return icons.attraction;
  }

  if (type === MARKER_TYPE.CITY) {
    return icons.city;
  }

  if (type === MARKER_TYPE.MUSEUM) {
    return icons.museum;
  }

  if (type === MARKER_TYPE.SANTA_FE_DPOT) {
    return icons.santa_fe_depot;
  }

  if (type === MARKER_TYPE.WATER_TAXI) {
    return icons.water_taxi;
  }
};

export const getPoiOptions = (showPOI = DEFAULT_SHOW_POI) => {
  if (showPOI) {
    return {
      // turnOnParks: mapOptions.turnOnParks,
      // turnOnAttractions: mapOptions.turnOnAttractions,
      // turnOnSportsComplex: mapOptions.turnOnSportsComplex,
    };
  }
  return {
    turnOnParks: {},
    turnOnAttractions: {},
    turnOnSportsComplex: {},
  };
};

export const getRoadsOptions = (checkStreets = DEFAULT_CHECK_STREET) => {
  if (checkStreets) {
    return {
      // turnOffRoads: mapOptions.turnOffRoads,
      // turnOffRoadLabels: mapOptions.turnOffRoadLabels,
    };
  }
  return {
    turnOffRoads: {},
    turnOffRoadLabels: {},
  };
};

export const getRouteClass = (routeId: number) => {
  if (routeId && REVENUE_ROUTES.indexOf(+routeId) != -1) {
    return ROUTE_CLASSES[routeId];
  }
  return ROUTE_CLASSES[ROUTE_IDS.NON_REVENUE];
};

export const icon_direction = (stopNumber: number) => {
  switch (true) {
    case HORIZONTAL_ICONS.indexOf(stopNumber) != -1:
      return 'info_vertical';
    case VERTICAL_ICONS.indexOf(stopNumber) != -1:
      return 'info_horizontal';
    default:
      return null;
  }
};

export const icon_options = (streetcar_icon_number: number) => {
  switch (true) {
    case streetcar_icon_number === 201801 ||
      streetcar_icon_number === 201802 ||
      streetcar_icon_number === 201807:
      return 'pink_options';
    case streetcar_icon_number === 201803 || streetcar_icon_number === 201804:
      return 'blue_options';
    case streetcar_icon_number === 201805 || streetcar_icon_number === 201806:
      return 'green_options';
    default:
      return null;
  }
};

export const poi_label_position = (id?: number) => {
  if (id && POI_LABEL_POSITION[id]) {
    return POI_LABEL_POSITION[id];
  } else {
    return { x: 0, y: 0 };
  }
};

export const stop_icon_anchor = (stopNumber: number) => {
  if (STOP_ICON_ARNCHOR[stopNumber]) {
    return STOP_ICON_ARNCHOR[stopNumber];
  } else {
    return { x: 9, y: 9 };
  }
};

export const stop_icon_origin = (stopNumber: number) => {
  if (STOP_ICON_ORIGIN[stopNumber]) {
    return STOP_ICON_ORIGIN[stopNumber];
  } else {
    return { x: 0, y: 0 };
  }
};

export const stop_label_position = (stopNumber: number) => {
  if (STOP_LABEL_POSITION[stopNumber]) {
    return STOP_LABEL_POSITION[stopNumber];
  } else {
    return { x: 0, y: 0 };
  }
};

export const streetcarDirection = (angle: number) => {
  if (angle > 315 || angle <= 45) {
    return ORIENTATION.NORTH;
  } else if (angle > 45 && angle <= 135) {
    return ORIENTATION.EAST;
  } else if (angle > 135 && angle <= 225) {
    return ORIENTATION.SOUTH;
  } else {
    return ORIENTATION.WEST;
  }
};

export const streetCarMaker = (
  asset: AssetData,
  streetcar: StreetCar,
  direction: ORIENTATION,
  /* eslint-disable */
  data: any
) => {
  return {
    ...streetcar,
    content: {
      route: getRouteName(asset.routeId),
      number: getStreetCarNumber(streetcar.streetcar),
      routeClass: getRouteClass(asset.routeId),
      opened: false,
    },
    icon:
      streetcar?.icon_options?.[`${direction}${streetcar.streetcar}`] ||
      data?.streetcar_options?.grey_options[direction],

    position: { lat: data.latitude, lng: data.longitude },
    type: MARKER_TYPE.STREET_CAR,
  };
};
