<template>
  <google-map></google-map>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from 'vue';
import GoogleMap from '@/components/GoogleMap.vue';
import { useStore } from 'vuex';
import { LOAD_DEVICE_SCREEN, LOAD_MAP_OPTIONS, mapOptions } from '@/utils';
import { SCREEN_TYPE } from '@/enums';

export default defineComponent({
  name: 'Kiosk',
  components: { GoogleMap },
  setup() {
    const store = useStore();

    onBeforeMount(() => {
      store.commit(LOAD_DEVICE_SCREEN, SCREEN_TYPE.KIOSK);
      store.commit(LOAD_MAP_OPTIONS, {
        ...mapOptions,
        styles: [
          ...(mapOptions?.styles || []),
          {
            featureType: 'road',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
              {
                visibility: 'simplified',
              },
            ],
          },
        ],
      });
    });

    return {};
  },
});
</script>

<style lang="scss" scoped></style>
