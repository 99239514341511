<template>
  <g-marker
    v-if="poiMarker"
    :address="poiMarker.address"
    :clickable="true"
    :content="poiMarker.content"
    :draggable="false"
    :generatedESN="poiMarker.esn"
    :icon="poiMarker.icon"
    :label="poiMarker.label || { text: poiMarker.title || '' }"
    :opened="poiMarker.opened"
    :position="poiMarker.position"
    :title="poiMarker.title"
    :type="poiMarker.type"
    :zIndex="100"
  >
    <template v-slot="{ marker, map }">
      <info-window v-if="poiMarker.content" :map="map" :marker="marker">
        <div class="marker-window poi" v-if="marker?.opened && marker.type === type">
          <div
            v-bind:class="[
              marker.content.googleLink && marker.content.ticketsLink ? 'bottom-margin' : null,
            ]"
          >
            <div class="parking-name" v-if="marker.title">{{ marker.title }}</div>
            <div class="parking-address" v-if="marker.address">
              {{ marker.address }}
            </div>
            <div class="parking-google-link" v-if="marker.content.googleLink">
              <a target="_blank" :href="marker.content.googleLink">Get Directions</a>
            </div>
          </div>
        </div>
      </info-window></template
    >
  </g-marker>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import InfoWindow from '@/components/InfoWindow.vue';
import GMarker from '@/components/GMarker.vue';
import { POIMarker } from '@/types';
import { MARKER_TYPE } from '@/enums';
import { POI_COLLECTION } from '@/utils';
export default defineComponent({
  name: 'Parking Marker',
  components: { GMarker, InfoWindow },
  props: {
    poi: Object as PropType<POIMarker>,
  },
  setup(props) {
    const type = ref(props.poi?.type);
    const poiCollection = ref(POI_COLLECTION);
    const poiMarker = ref(props.poi);
    return { poiCollection, poiMarker, type };
  },
});
</script>

<style scoped lang="scss"></style>
