export const autheticate = 'autheticate';
export const checkCannedMessages = 'checkCannedMessages';
export const clearWindowInfo = 'clearWindowInfo';
export const getAssets = 'getAssets';
export const getDevices = 'getDevices';
export const hideParking = 'hideParking';
export const hidePOI = 'hidePOI';
export const hideRoadsAndLabels = 'hideRoadsAndLabels';
export const hideSpokies = 'hideSpokies';
export const hideTransitCenter = 'hideTransitCenter';
export const init = 'init';
export const initialMapControls = 'initialMapControls';
export const initializeParking = 'initializeParking';
export const initializePOI = 'initializePOI';
export const initializeSpokies = 'initializeSpokies';
export const initializeTransitCenterMarker = 'initializeTransitCenterMarker';
export const insertParkingMarkers = 'insertParkingMarkers';
export const insertStreetCarMarker = 'insertStreetCarMarker';
export const insertSpokiesMarker = 'insertSpokiesMarker';
export const loadStreetCarDeviceESN = 'loadStreetCarDeviceESN';
// export const LoadEnabledStreetCarDeviceESNs = "LoadEnabledStreetCarDeviceESNs";
// export const loadFreshDevices = "loadFreshDevices";
// export const loadStreetCarDeviceESNs = 'loadStreetCarDeviceESNs';
export const loadIcons = 'loadIcons';
export const loadLocalStorage = 'loadLocalStorage';
export const loadMapOptions = 'loadMapOptions';
export const loadMapStyles = 'loadMapStyles';
export const loadStops = 'loadStops';
export const loadStopSponsorsAndStopGoogleLinks = 'loadStopSponsorsAndStopGoogleLinks';
export const loadStreetCarIconOptions = 'loadStreetCarIconOptions';
export const loadStreetCars = 'loadStreetCars';
export const loadUserLocationIcon = 'loadUserLocationIcon';
export const reloadMapOptionsAndStyles = 'reloadMapOptionsAndStyles';
export const showPOI = 'showPOI';
export const showParking = 'showParking';
export const showRoadsAndLabels = 'showRoadsAndLabels';
export const showSpokies = 'showSpokies';
export const showTransitCenter = 'showTransitCenter';
export const subscribeToUsfleetDataStream = 'subscribeToUsfleetDataStream';
export const subscribeToUsfleetStopsDataStream = 'subscribeToUsfleetStopsDataStream';
export const toggleCheckParking = 'toggleCheckParking';
export const toggleCheckSpokies = 'toggleCheckSpokies';
export const toggleCheckStreets = 'toggleCheckStreets';
export const toggleShowPOI = 'toggleShowPOI';
export const toggleStopLabel = 'toggleStopLabel';
export const toggleCheckTransitCenter = 'toggleCheckTransitCenter';
export const unSubscribeToUsfleetDataStream = 'unSubscribeToUsfleetDataStream';
export const unSubscribeToUsfleetStopsDataStream = 'unSubscribeToUsfleetStopsDataStream';
export const updateSetTime = 'updateSetTime';
export const updateStreetCarMarker = 'updateStreetCarMarker';
export const updateToken = 'updateToken';
export const updateUserLocation = 'updateUserLocation';
export const updateWindowInfoStatus = 'updateWindowInfoStatus';
export const updateStopsMessages = 'updateStopsMessages';
