import { MapTypeId } from '@/enums';
import { State } from '@/types';
import { ARROWS, CENTER, NE_BOUND, STROKE_WEIGHT, styles, SW_BOUND, ZOOM } from '@/utils';
export const state: State = {
  alertMessage: {},
  alertMessageOpened: false,
  arrows: ARROWS,
  assetData: [],
  bricktownDurations: {},
  bricktownRoute: [],
  bricktownRouteOptions: {
    strokeColor: '#288dc1',
    strokeWeight: STROKE_WEIGHT,
  },
  center: CENTER,
  customBricktownMessage: '',
  customDowntownMessage: '',
  devices: [],
  downtownDurations: {},
  downtownRoute: [],
  downtownRouteOptions: {
    strokeColor: '#d51067',
    strokeWeight: STROKE_WEIGHT,
  },
  google: undefined,
  icons: undefined,
  inServiceStreetCarESN: [],
  map: undefined,
  mapOptions: undefined,
  mapTypeStyles: styles,
  mapTypeId: MapTypeId.ROADMAP,
  northEastBound: NE_BOUND,
  openWindowMarker: null,
  openWindowMarkerKey: undefined,
  openMobileLegend: false,
  parkings: [],
  parkingMarkers: {},
  pois: [],
  poiMarkers: {},
  poiOverlay: undefined,
  screen_type: undefined,
  setTime: parseInt(`${Date.now()}`, 10),
  southWestBound: SW_BOUND,
  spokies: [],
  spokiesMarkers: {},
  sponsorsAndGoogleLink: null,
  stops: [],
  stopMarkers: {},
  streetcar_icon_options: null,
  streetcarDeviceESN: null,
  // streetCarDeviceESNs: [],
  streetcars: [],
  streetCarMarkers: {},
  token: null,
  transitCenterMarker: undefined,
  userIcon: null,
  userLocation: undefined,
  usFleetSubscription: undefined,
  usFleetStopsSubscription: undefined,
  zoom: ZOOM,
};
