
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import {
  clearWindowInfo,
  OPEN_MARKER_WINDOW,
  OPEN_MARKER_WINDOW_KEY,
  updateWindowInfoStatus,
} from '@/utils';
export default defineComponent({
  name: 'InfoWindow',
  props: {
    disableAutoPan: {
      type: Boolean,
      default: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const google = computed(() => store.getters.google);
    const infoRef = ref();
    const info = ref();
    const openWindowMarker = computed(() => store.state.openWindowMarker);
    const openWindowMarkerKey = computed(() => store.state.openWindowMarkerKey);
    const _addEvents = () => {
      props.marker.addListener('click', () => {
        if (!openWindowMarker.value) {
          // props.marker.content.opened = true
          info.value.open(props.map, props.marker);
          store.dispatch(updateWindowInfoStatus, {
            infoWindow: info.value,
            key: props.marker.unique_key,
          });
          info.value.addListener('closeclick', () => {
            // props.marker.content.opened = false
            store.dispatch(clearWindowInfo);
          });
        } else {
          if (openWindowMarkerKey.value === props.marker.unique_key) {
            // props.marker.content.opened = false
            openWindowMarker.value.close();
            store.dispatch(clearWindowInfo);
          } else {
            openWindowMarker.value.close();
            // props.marker.content.opened = true
            info.value.open(props.map, props.marker);
            store.dispatch(updateWindowInfoStatus, {
              infoWindow: info.value,
              key: props.marker.unique_key,
            });
            info.value.addListener('closeclick', () => {
              // props.marker.content.opened = false
              store.dispatch(clearWindowInfo);
            });
          }
        }
      });
    };
    onMounted(async () => {
      const config = {
        content: infoRef.value,
        disableAutoPan: props.disableAutoPan,
      };
      info.value = new google.value.maps.InfoWindow(config);
      _addEvents();
    });
    onUnmounted(() => {
      info.value.close();
    });
    return { infoRef };
  },
});
