export enum STOP_NAMES {
  EAST_BRICKTOWN = 'East Bricktown',
  BALL_PARK = 'Ball Park',
  SANTA_FE_HUB = 'Santa Fe Hub',
  ARENA = 'Arena',
  SCISSORTAIL_PARK = 'Scissortail Park',
  MYRIAD_GARDENS = 'Myriad Gardens',
  LIBRARY = 'Library',
  TRANSIT_CENTER = 'Transit Center',
  FEDERAL_COURTHOUSE = 'Federal Courthouse',
  BROADWAY_AVENUE = 'Broadway Avenue',
  AUTOMOBILE_ALLEY = 'Automobile Alley',
  ART_PARK = 'Art park',
  NORTH_HUDSON = 'North Hudson',
  DEWEY_AVENUE = 'Dewey Avenue',
  MIDTOWN = 'Midtown',
  NW_10TH_STREET = 'NW 10th Street',
  LAW_SCHOOL = 'Law School',
  MEMORIAL_MUSEUM = 'Memorial Museum',
  BUSINESS_DISTRICT = 'Business District',
  CENTRURY_CENTER = 'Century Center',
  BRICKTOWN = 'Bricktown',
  MICKEY_MANTLE = 'Mickey Mantle',
}
