<template>
  <g-marker
    v-if="stopMarker"
    :clickable="true"
    :content="stopMarker.content"
    :draggable="false"
    :generatedESN="stopMarker.esn"
    :icon="stopMarker.icon"
    :label="label"
    :opened="stopMarker.opened"
    :position="stopMarker.position"
    :title="stopMarker.title"
    :type="stopMarker.type"
    :zIndex="100"
  >
    <template v-slot="{ marker, map }">
      <info-window v-if="stopMarker?.content" :map="map" :marker="marker">
        <div class="marker-window stop" v-if="marker?.opened && marker.type === type">
          <div v-if="marker.content.sponsor" class="red-bottom-border">
            <div class="sponsor-wrapper">
              <div class="sponsor">
                <div v-if="marker.content.sponsorLink">
                  <div class="sponsor-prefix">Sponsored by:</div>
                  <a class="sponsor-link" target="_blank" :href="marker.content.sponsorLink">{{
                    marker.content.sponsor
                  }}</a>
                </div>
                <div v-else>
                  <div class="sponsor-prefix">Sponsored by:</div>
                  <p class="sponsor-link">{{ marker.content.sponsor }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="sponsor-wrapper">
              <div class="sponsor">
                <a
                  class="sponsor-link"
                  target="_blank"
                  href="http://okcstreetcar.com/sponsors/sponsorships"
                  >Become a Sponsor</a
                >
              </div>
            </div>
          </div>

          <div
            v-bind:class="[
              marker.content.googleLink && marker.content.ticketLink ? 'bottom-margin' : null,
            ]"
          >
            <!-- marker title -->
            <div class="stop-name" v-if="marker.content.title">
              {{ marker.content.title }}
            </div>

            <div class="route-wrapper">
              <div class="route-name downtown">
                Downtown Loop
              </div>
              <div class="arrivals">
                <div v-if="customDowntownMessage">
                  <p>{{ customDowntownMessage }}</p>
                </div>
                <div v-else-if="downtownDurations[marker?.content?.stopId]?.durations?.length > 0">
                  <span class="arrival-prefix">Est. Arrivals:</span>
                  <ul>
                    <li
                      v-for="(duration, index) in downtownDurations[marker?.content?.stopId]
                        ?.durations || []"
                      :key="index"
                    >
                      {{ duration }}m<span
                        v-if="
                          index < downtownDurations[marker?.content?.stopId]?.durations?.length - 1
                        "
                        >,</span
                      >
                    </li>
                  </ul>
                </div>
                <div class="route-wrapper downtown no-service" v-else>
                  No Service
                </div>
              </div>
            </div>

            <div
              v-if="
                customBricktownMessage ||
                  bricktownDurations[marker?.content?.stopId]?.durations?.length > 0
              "
            >
              <div class="route-wrapper">
                <div class="route-name bricktown">
                  Bricktown Loop
                </div>
                <div class="arrivals">
                  <div v-if="customBricktownMessage">
                    <p>{{ customBricktownMessage }}</p>
                  </div>
                  <div v-else>
                    <span class="arrival-prefix">Est. Arrivals:</span>
                    <ul>
                      <li
                        v-for="(duration, index) in bricktownDurations[marker?.content?.stopId]
                          ?.durations || []"
                        :key="index"
                      >
                        {{ duration }}m<span
                          v-if="
                            index <
                              bricktownDurations[marker?.content?.stopId]?.durations?.length - 1
                          "
                          >,</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="info-links-container"
            v-if="
              marker.content.googleLink || marker.content.alertMessage || marker.content.ticketLink
            "
          >
            <div class="info-links-wrapper">
              <div v-if="marker?.content?.googleLink">
                <a target="_blank" :href="marker?.content?.googleLink">Get Directions</a>
              </div>
              <div class="info-link-tickets" v-if="marker?.content?.ticketLink">
                <a target="_blank" :href="marker.content.ticketLink">Get Tickets</a>
              </div>
            </div>
            <div class="tracked-by-usfleet">
              <a target="_black" href="https://www.usfleettracking.com"
                >Tracked by US Fleet Tracking</a
              >
            </div>
            <div class="alert-message" v-if="marker?.content?.alertMessage">
              Alert: {{ marker?.content?.alertMessage || '' }}
            </div>
          </div>
        </div>
      </info-window></template
    >
  </g-marker>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { useStore } from 'vuex';
import InfoWindow from '@/components/InfoWindow.vue';
import GMarker from '@/components/GMarker.vue';
import { StopMarker } from '@/types';
export default defineComponent({
  name: 'Stop Marker',
  components: { GMarker, InfoWindow },
  props: {
    stop: Object as PropType<StopMarker>,
  },
  setup(props) {
    const store = useStore();
    const bricktownDurations = computed(() => store.getters.getBricktownDurations);
    const customBricktownMessage = computed(() => store.state.customBricktownMessage);
    const customDowntownMessage = computed(() => store.state.customDowntownMessage);
    const downtownDurations = computed(() => store.getters.getDowntownDurations);
    const showCustomMessage = computed(() => store.getters.getCustomMessageSwitch);

    const label = ref({
      text: props?.stop?.label || '',
    });
    const stopMarker = ref(props.stop);
    const type = ref(props.stop?.type);

    return {
      bricktownDurations,
      customBricktownMessage,
      customDowntownMessage,
      downtownDurations,
      label,
      showCustomMessage,
      stopMarker,
      type,
    };
  },
});
</script>

<style scoped lang="scss"></style>
