import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Device from '@/views/Device.vue';
import Kiosk from '@/views/Kiosk.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'devices',
    component: Device,
  },
  {
    path: '/kiosk',
    name: 'kiosk',
    component: Kiosk,
  },
  {
    path: '/:catchAll(.*)',
    component: Device,
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory('/#/'),
  routes,
});

export default router;
