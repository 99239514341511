import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import '@/assets/scss/base.scss';

// const plugin = (app: any, options: any)=>
// {
//   return {
//   install: (app: any, options: any) => {
//     // Plugin code goes here
//     app.config.globalProperties.$translate = (key:string) => {
//       return key.split('.').reduce((o, i) => {
//         if (o) return o[i]
//       }, options)
//     },
//     app.provide('i18n', options)
//   }
// }}

const app = createApp(App)
  .use(store)
  .use(router)
  .mount('#app');

// app.use(plugin,{})

// console.log(app.$el);
