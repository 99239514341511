<template>
  <div class="map-loader">
    <div class="google-map" ref="googleMapRef"></div>
    <template v-if="Boolean(this.map) && Boolean(this.google)">
      <slot />
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue';
import { Loader, LoaderOptions } from 'google-maps';
import {
  API_TOKEN,
  DEFAULT_CENTER,
  DEFAULT_ZOOM,
  initialMapControls,
  LOAD_GOOGLE,
  LOAD_MAP,
} from '@/utils';
import { LatLng, MapConfig, State } from '@/types';
import { MAP_TYPE_ID } from '@/enums';
import { Store, useStore } from 'vuex';
export default defineComponent({
  name: 'map-loader',
  props: {
    apiKey: String,
    center: Object as PropType<LatLng>,
    mapTypeId: String as PropType<MAP_TYPE_ID>,
    zoom: Number,
  },
  setup(props) {
    const store: Store<State> = useStore();
    const apiKey = ref<string>(props.apiKey || API_TOKEN);
    const center = ref(props.center || DEFAULT_CENTER);
    const google = ref();
    const map = ref<google.maps.Map>();
    const googleMapRef = ref();
    const mapTypeId = ref(props.mapTypeId);
    const zoom = ref(props.zoom || DEFAULT_ZOOM);
    const mapConfig = ref();
    const mapOptions = computed(() => store.getters.mapOptions);
    const gInstance = computed(() => store.getters.google);

    const initializeMap = async () => {
      if (!gInstance.value) {
        const options: LoaderOptions = {};
        const loader = new Loader(apiKey.value, options);
        google.value = await loader.load();
      } else {
        google.value = gInstance.value;
      }
      map.value = new google.value.maps.Map(googleMapRef.value, mapConfig.value);
      await store.commit(LOAD_GOOGLE, google.value);
      await store.commit(LOAD_MAP, map.value);
      await store.dispatch(initialMapControls); // loads using the local storage values
    };

    onMounted(async () => {
      // setting configurations
      const config: MapConfig = {
        ...mapOptions.value,
        center: center.value,
        mapTypeId: mapTypeId.value,
        zoom: zoom.value,
      };
      mapConfig.value = config;
      await initializeMap();
    });

    onUnmounted(async () => {
      google.value = undefined;
      map.value = undefined;
      // await store.commit(LOAD_GOOGLE, undefined);
      // await store.commit(LOAD_MAP, undefined);
    });

    return {
      googleMapRef,
      google,
      map,
    };
  },
});
</script>

<style scoped lang="scss"></style>
