import { ROUTE_IDS } from '@/enums';
import { CannedMessage } from '@/types';

export const specificRouteMessages = (routeMessages: CannedMessage[]) => {
  const downtownRoutes = routeMessages?.find(
    (routeMessage: CannedMessage) => routeMessage.routeId === ROUTE_IDS.DOWNTOWN
  );

  const bricktownRoutes = routeMessages?.find(
    (routeMessage: CannedMessage) => routeMessage.routeId === ROUTE_IDS.BRICKTOWN
  );

  return [bricktownRoutes, downtownRoutes];
};
