import { MapTypeStyle } from '@/types';

export const styles: MapTypeStyle[] = [
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  // {
  //   featureType: 'road.arterial',
  //   elementType: 'geometry',
  //   stylers: [
  //     {
  //       weight: 2,
  //     },
  //   ],
  // },
  {
    featureType: 'transit',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e6e7e8',
      },
    ],
  },
];

export const OFF_POI_STYLE: { [key: string]: MapTypeStyle } = {
  poi: {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
};

export const ON_POI_STYLE: { [key: string]: MapTypeStyle } = {
  poi: {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
};

export const OFF_POI_ATTRACTION_STYLE: { [key: string]: MapTypeStyle } = {
  'poi.attraction': {
    featureType: 'poi.attraction',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
};

export const ON_POI_ATTRACTION_STYLE: { [key: string]: MapTypeStyle } = {
  'poi.attraction': {
    featureType: 'poi.attraction',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
};

export const OFF_POI_PARK_STYLE: { [key: string]: MapTypeStyle } = {
  'poi.park': {
    featureType: 'poi.park',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
};

export const ON_POI_PARK_STYLE: { [key: string]: MapTypeStyle } = {
  'poi.park': {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
};

export const OFF_POI_SPORTS_COMPLEX_STYLE: { [key: string]: MapTypeStyle } = {
  'poi.sports_complex': {
    featureType: 'poi.sports_complex',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
};

export const ON_POI_SPORTS_COMPLEX_STYLE: { [key: string]: MapTypeStyle } = {
  'poi.sports_complex': {
    featureType: 'poi.sports_complex',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
};

export const OFF_ROAD_STYLE: { [key: string]: MapTypeStyle } = {
  road: {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }],
  },
  'road-label': {
    featureType: 'road',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
};

export const ON_ROAD_STYLE: { [key: string]: MapTypeStyle } = {
  road: {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ visibility: 'on' }],
  },
};
