import { MARKER_TYPE } from '@/enums';
import { POIMarker } from '@/types';

export const poi: POIMarker[] = [
  {
    id: 9,
    title: 'Oklahoma City National Memorial & Museum',
    address: '620 N Harvey Ave',
    position: { lat: 35.4731458, lng: -97.5170591 },
    googleLink: 'https://g.page/OKCNM?share',
    type: MARKER_TYPE.MUSEUM,
    label: { text: 'OKC National Memorial & Museum' },
    iconAnchor: { x: 0, y: 0 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    id: 10,
    title: 'Red Earth',
    address: '100 N Broadway Ave #2750',
    position: { lat: 35.4680239, lng: -97.513941 },
    googleLink: 'https://goo.gl/maps/ycyE5hfNB3LsdY9w6',
    type: MARKER_TYPE.MUSEUM,
    iconAnchor: { x: 0, y: 0 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    id: 11,
    title: 'American Banjo Museum',
    address: '9 E Sheridan Ave',
    position: { lat: 35.46698, lng: -97.511517 },
    googleLink: 'https://goo.gl/maps/bQJ2G2Pj24H1HVtz9',
    type: MARKER_TYPE.MUSEUM,
    label: { text: 'Banjo Museum' },
    iconAnchor: { x: 0, y: 0 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    id: 12,
    title: 'Factory Obscura',
    address: '25 NW 9th St',
    position: { lat: 35.4772648, lng: -97.5133563 },
    googleLink: 'https://g.page/factory-obscura-okc?share',
    type: MARKER_TYPE.MUSEUM,
    label: { text: 'Factory Obscura' },
    iconAnchor: { x: 0, y: 0 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    id: 13,
    title: 'Oklahoma Sports Hall of Fame',
    address: '20 S Mickey Mantle Dr',
    position: { lat: 35.466, lng: -97.508922 },
    googleLink: 'https://goo.gl/maps/9oezxJVKg2aC3pF66',
    type: MARKER_TYPE.MUSEUM,
    label: { text: 'Sports HOF' },
    iconAnchor: { x: 0, y: -6 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    id: 14,
    title: 'Oklahoma Contemporary Arts Center Showroom',
    address: '1146 N Broadway Dr',
    position: { lat: 35.4800595, lng: -97.5133348 },
    googleLink: 'https://goo.gl/maps/tJJQdLTtWBz8G1GDA',
    type: MARKER_TYPE.MUSEUM,
    label: { text: 'Oklahoma Contemporary' },
    iconAnchor: { x: 0, y: 0 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    id: 15,
    title: 'Oklahoma City Museum of Art',
    address: '415 Couch Dr',
    position: { lat: 35.469659, lng: -97.520703 },
    googleLink: 'https://goo.gl/maps/hRMrtESq2Xwxfqi97',
    type: MARKER_TYPE.MUSEUM,
    label: { text: 'OKC Museum of Art' },
    iconAnchor: { x: 0, y: 0 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    id: 16,
    title: 'City Hall',
    address: '200 N Walker Ave',
    position: { lat: 35.4689249, lng: -97.5207052 },
    googleLink: 'https://goo.gl/maps/xDEdjsWEJhhAmrq67',
    type: MARKER_TYPE.CITY,
    label: { text: 'City Hall' },
    iconAnchor: { x: 0, y: 0 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    id: 17,
    title: 'Downtown Library',
    address: '300 Park Ave',
    position: { lat: 35.468513, lng: -97.518602 },
    googleLink: 'https://g.page/metrolibrarydowntown?share',
    type: MARKER_TYPE.CITY,
    label: { text: 'Library' },
    iconAnchor: { x: 0, y: 0 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    id: 18,
    title: 'Chesapeake Energy Arena',
    address: '100 W Reno Ave',
    position: { lat: 35.463979, lng: -97.515816 },
    googleLink: 'https://goo.gl/maps/DQWUWtq2S9GFzqPD7',
    type: MARKER_TYPE.ATTRACTION,
    label: { text: 'Arena' },
    iconAnchor: { x: 0, y: 0 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    id: 19,
    title: 'Bricktown Ballpark',
    address: '2 S Mickey Mantle Dr',
    position: { lat: 35.465179, lng: -97.507723 },
    googleLink: 'https://goo.gl/maps/pSjC9Shi1uVBK9Au8',
    type: MARKER_TYPE.ATTRACTION,
    label: { text: 'Ballpark' },
    iconAnchor: { x: 0, y: 0 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    id: 20,
    title: 'Cox Convention Center',
    address: '1 Myriad Gardens',
    position: { lat: 35.465398, lng: -97.5146015 },
    googleLink: 'https://goo.gl/maps/XaPkfRR1hjUBCGey5',
    type: MARKER_TYPE.ATTRACTION,
    label: { text: 'Convention Center' },
    iconAnchor: { x: 0, y: 0 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    id: 21,
    title: 'The Criterion',
    address: '500 E Sheridan Ave',
    position: { lat: 35.466084, lng: -97.503981 },
    googleLink: 'https://goo.gl/maps/ih3v7AM56nCwFzyc9',
    type: MARKER_TYPE.ATTRACTION,
    label: { text: 'Criterion' },
    iconAnchor: { x: 0, y: 0 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    id: 22,
    title: 'Civic Center Music Hall',
    address: '201 N Walker Ave',
    position: { lat: 35.469053, lng: -97.523434 },
    googleLink: 'https://g.page/okcciviccenter?share',
    type: MARKER_TYPE.ATTRACTION,
    label: { text: 'Civic Center' },
    iconAnchor: { x: 0, y: 0 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    id: 23,
    title: 'Oklahoma City Santa Fe Depot',
    address: '100 S E K Gaylord Blvd',
    position: { lat: 35.4653746, lng: -97.5126294 },
    googleLink: 'https://goo.gl/maps/wYf5QMAD4hVBRbueA',
    type: MARKER_TYPE.SANTA_FE_DPOT,
    label: { text: 'Sante Fe Hub' },
    iconAnchor: { x: 0, y: 0 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    id: 24,
    title: 'Bricktown Water Taxi',
    address: '111 S Mickey Mantle Dr',
    position: { lat: 35.46504, lng: -97.509354 },
    googleLink: 'https://g.page/bricktownwatertaxi?share',
    type: MARKER_TYPE.WATER_TAXI,
    label: { text: 'Water Taxi' },
  },
];
