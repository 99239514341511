import { State } from '@/types';

export const getters = {
  getBricktownDurations: (state: State) => {
    return state.bricktownDurations;
  },
  getCustomBricktownMessage: (state: State) => {
    return state.customBricktownMessage;
  },
  getCustomDowntownMessage: (state: State) => {
    return state.customDowntownMessage;
  },
  getCustomMessageSwitch: (state: State) => {
    return state.customMessage;
  },
  getDowntownDurations: (state: State) => {
    return state.downtownDurations;
  },
  getInserviceESN: (state: State) => {
    return state.inServiceStreetCarESN || [];
  },
  getParkings: (state: State) => {
    return state.parkings;
  },
  getPois: (state: State) => {
    return state.pois;
  },
  getScreenType: (state: State) => {
    return state.screen_type;
  },
  getSpokies: (state: State) => {
    return state.spokies;
  },
  getStops: (state: State) => {
    return state.stops;
  },
  getStreetCars: (state: State) => {
    return state.streetCarMarkers || {};
  },
  getUserLocation: (state: State) => {
    return state.userLocation;
  },
  getUserIcon: (state: State) => {
    return { url: state.icons?.user_location || '' };
  },
  google: (state: State) => {
    return state.google;
  },
  map: (state: State) => {
    return state.map;
  },
  mapOptions: (state: State) => {
    return state.mapOptions;
  },
};
