
import { computed, defineComponent, PropType, ref } from 'vue';
import InfoWindow from '@/components/InfoWindow.vue';
import GMarker from '@/components/GMarker.vue';
import { POIMarker } from '@/types';
import { MARKER_TYPE } from '@/enums';
import { POI_COLLECTION } from '@/utils';
export default defineComponent({
  name: 'Parking Marker',
  components: { GMarker, InfoWindow },
  props: {
    poi: Object as PropType<POIMarker>,
  },
  setup(props) {
    const type = ref(props.poi?.type);
    const poiCollection = ref(POI_COLLECTION);
    const poiMarker = ref(props.poi);
    return { poiCollection, poiMarker, type };
  },
});
