import { SpokieMarker } from '@/types';

export const spokies: SpokieMarker[] = [
  {
    name: 'Deep Deuce',
    address: '131 NE 2ND St.',
    position: { lat: 35.46995000746384, lng: -97.50930547714232 },
    iconAnchor: { x: 9, y: 9 },
    iconOrigin: { x: 0, y: 0 },
    googleLink: 'https://goo.gl/maps/3F3iwE4qRMAhzTocA',
  },
  {
    name: 'Riversport Station',
    address: '715 SW 6th St.',
    position: { lat: 35.45941155638679, lng: -97.4994134902954 },
    iconAnchor: { x: 9, y: 9 },
    iconOrigin: { x: 0, y: 0 },
    googleLink: 'https://goo.gl/maps/PZfjtPpuxsUGWm8t8',
  },
  {
    name: 'Automobile Alley',
    address: '90 NW 9th St.',
    position: { lat: 35.4769224522563, lng: -97.51336097717285 },
    iconAnchor: { x: 0, y: 5 },
    iconOrigin: { x: 0, y: 0 },
    googleLink: 'https://goo.gl/maps/DxrxZpzE5EWuwNHK9',
  },
  {
    name: 'Arts District',
    address: '299-261 Latting Cir.',
    position: { lat: 35.469049, lng: -97.519706 },
    iconAnchor: { x: 12, y: 12 },
    iconOrigin: { x: 0, y: 0 },
    googleLink: 'https://goo.gl/maps/PZaGT3AdFaBW2Dpq6',
  },
  {
    name: 'Midtown Circle',
    address: '1100 Classen Dr.',
    position: { lat: 35.47929888674646, lng: -97.52142906188965 },
    iconAnchor: { x: 9, y: 15 },
    iconOrigin: { x: 0, y: 0 },
    googleLink: 'https://goo.gl/maps/yGwYqzEvCv2evCcdA',
  },
  {
    name: 'Convention Center',
    address: '1 Myriad Gdns',
    position: { lat: 35.464481, lng: -97.515994 },
    iconAnchor: { x: 9, y: 9 },
    iconOrigin: { x: 0, y: 0 },
    googleLink: 'https://goo.gl/maps/b2jCLDn6TAziJPcXA',
  },
  {
    name: 'Santa Fe',
    address: '34-2 SEK Gaylord Blvd',
    position: { lat: 35.466257, lng: -97.512882 },
    iconAnchor: { x: 9, y: 0 },
    iconOrigin: { x: 0, y: 0 },
    googleLink: 'https://goo.gl/maps/i9c5RJCPPX3c3NUMA',
  },
  {
    name: 'Bricktown Ballpark',
    address: '208 Johnny Bench Drive',
    position: { lat: 35.46481202968145, lng: -97.50844717025757 },
    iconAnchor: { x: 5, y: 9 },
    iconOrigin: { x: 0, y: 0 },
    googleLink: 'https://goo.gl/maps/ZT595TpekZFDtuZU9',
  },
];
