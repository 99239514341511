
import { computed, defineComponent, PropType, ref } from 'vue';
import InfoWindow from '@/components/InfoWindow.vue';
import GMarker from '@/components/GMarker.vue';
import { SpokieMarker } from '@/types';
export default defineComponent({
  name: 'Spokie Marker',
  components: { GMarker, InfoWindow },
  props: {
    spokie: Object as PropType<SpokieMarker>,
  },
  setup(props) {
    const spokieMarker = ref(props.spokie);
    const type = ref(props.spokie?.type);
    return { spokieMarker, type };
  },
});
