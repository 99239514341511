import { TransitCenterMarker } from '@/types';

export const transitCenter: TransitCenterMarker = {
  name: 'Transit Center',
  address: '420 NW 5th St.',
  googleLink: 'https://goo.gl/maps/1cGUtFeiokcEm7sV6',
  embarkServices: 'https://embarkok.com/learn/services/',
  position: {
    lat: 35.47254071700318,
    lng: -97.51976609230042,
  },
  iconAnchor: {
    x: 9,
    y: 2,
  },
  iconOrigin: {
    x: 0,
    y: 0,
  },
};
