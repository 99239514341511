<template>
  <router-view />
</template>
<script lang="ts">
import { defineComponent, onBeforeMount, onMounted } from 'vue';
import { useStore } from 'vuex';
import { init, loadIcons } from './utils';

export default defineComponent({
  name: 'App',
  setup() {
    const store = useStore();

    onBeforeMount(async () => {
      store.dispatch(loadIcons);
    });

    onMounted(async () => {
      store.dispatch(init);
    });
  },
});
</script>

<style scoped lang="scss"></style>
