
import { computed, defineComponent, PropType, ref } from 'vue';
import InfoWindow from '@/components/InfoWindow.vue';
import GMarker from '@/components/GMarker.vue';
import { ParkingMarker } from '@/types';
export default defineComponent({
  name: 'Transit Center Marker',
  components: { GMarker, InfoWindow },
  props: {
    transitCenter: Object as PropType<ParkingMarker>,
  },
  setup(props) {
    const transitCenterMarker = ref(props.transitCenter);
    const type = ref(props.transitCenter?.type);
    return { transitCenterMarker, type };
  },
});
