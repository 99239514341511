<template>
  <div class="device">
    <disclaimer-banner></disclaimer-banner>
    <map-control></map-control>
    <google-map></google-map>
    <!--mobile-disclaimer-banner -->
    <div class="mobile-disclaimer-banner hide-on-desktop">
      Route Colors Do Not Indicate Streetcar Color
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, onMounted, onUnmounted } from 'vue';

import DisclaimerBanner from '@/components/DisclaimerBanner.vue';
import GoogleMap from '@/components/GoogleMap.vue';
import MapControl from '@/components/MapControl.vue';
import { useStore } from 'vuex';
import {
  loadLocalStorage,
  loadUserLocationIcon,
  LOAD_DEVICE_SCREEN,
  LOAD_MAP_OPTIONS,
  mapOptions,
  updateUserLocation,
  USER_LOCATION_INTERVAL_TIME,
} from '@/utils';
import { SCREEN_TYPE } from '@/enums';

export default defineComponent({
  name: 'Device',
  components: { DisclaimerBanner, GoogleMap, MapControl },

  setup() {
    let userLocationId: number;

    const store = useStore();

    const geoLocate = async () => {
      store.dispatch(updateUserLocation);
      userLocationId = setInterval(
        () => store.dispatch(updateUserLocation),
        USER_LOCATION_INTERVAL_TIME
      );
    };

    onBeforeMount(() => {
      store.commit(LOAD_DEVICE_SCREEN, SCREEN_TYPE.DEVICE);
      store.commit(LOAD_MAP_OPTIONS, mapOptions);
    });

    onMounted(async () => {
      await store.dispatch(loadLocalStorage); // control module action
      await store.dispatch(loadUserLocationIcon);
      await geoLocate();
    });

    onUnmounted(() => {
      clearInterval(userLocationId);
    });
    return {};
  },
});
</script>

<style lang="scss" scoped></style>
