import moment from 'moment';

export const pastDate = (testDate?: string) => {
  if (testDate) {
    const givenDate = moment(testDate);
    const now = moment();
    return givenDate.isBefore(now);
  }
  return false;
};
