import { VISIBILITY } from '@/enums';
import { MapOptions, MapTypeStyle } from '@/types';
import { OFF_ROAD_STYLE, ON_ROAD_STYLE } from './styles';

export const roadStyles = (options?: MapOptions, visibility = VISIBILITY.ON): MapTypeStyle[] => {
  const roadStyle = visibility === VISIBILITY.ON ? ON_ROAD_STYLE : OFF_ROAD_STYLE;

  const optionFeatures = options?.styles?.map((style: MapTypeStyle) => style.featureType) || [];

  if (optionFeatures.includes('road')) {
    const styles: MapTypeStyle[] =
      options?.styles?.map((style: MapTypeStyle) => {
        if (style?.featureType === 'road') {
          return roadStyle.road;
        }
        return style;
      }) || [];
    if (visibility === VISIBILITY.OFF) {
      return [...styles, OFF_ROAD_STYLE['road-label']];
    }
    return styles;
  } else {
    return options?.styles && Array.isArray(options?.styles)
      ? [...options.styles, roadStyle.road]
      : [roadStyle.road];
  }
};
