export enum MAP_TYPE_ID {
  ROAD_MAP = 'roadmap', // displays the default road map view. This is the default map type.
  SATELLITE = 'satellite', // displays Google Earth satellite images.
  HYBRID = 'hybrid', // displays a mixture of normal and satellite views.
  TERRAIN = 'terrain', // displays a physical map based on terrain information.
}

// FROM GOOGLE TYPES
/**
 * Identifiers for common MapTypes. Specify these by value, or by using the
 * constant's name. For example, 'satellite' or
 * google.maps.MapTypeId.SATELLITE.
 */
export enum MapTypeId {
  /** This map type displays a transparent layer of major streets on satellite images. */
  HYBRID = 'hybrid',
  /** This map type displays a normal street map. */
  ROADMAP = 'roadmap',
  /** This map type displays satellite images. */
  SATELLITE = 'satellite',
  /** This map type displays maps with physical features such as terrain and vegetation. */
  TERRAIN = 'terrain',
}
