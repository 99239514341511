
import { computed, defineComponent, PropType, ref } from 'vue';
import { useStore } from 'vuex';
import InfoWindow from '@/components/InfoWindow.vue';
import GMarker from '@/components/GMarker.vue';
import { StopMarker } from '@/types';
export default defineComponent({
  name: 'Stop Marker',
  components: { GMarker, InfoWindow },
  props: {
    stop: Object as PropType<StopMarker>,
  },
  setup(props) {
    const store = useStore();
    const bricktownDurations = computed(() => store.getters.getBricktownDurations);
    const customBricktownMessage = computed(() => store.state.customBricktownMessage);
    const customDowntownMessage = computed(() => store.state.customDowntownMessage);
    const downtownDurations = computed(() => store.getters.getDowntownDurations);
    const showCustomMessage = computed(() => store.getters.getCustomMessageSwitch);

    const label = ref({
      text: props?.stop?.label || '',
    });
    const stopMarker = ref(props.stop);
    const type = ref(props.stop?.type);

    return {
      bricktownDurations,
      customBricktownMessage,
      customDowntownMessage,
      downtownDurations,
      label,
      showCustomMessage,
      stopMarker,
      type,
    };
  },
});
