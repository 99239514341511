import moment from 'moment';

export const futureDate = (testDate?: string) => {
  if (testDate) {
    const givenDate = moment(testDate);
    const now = moment();
    return now.isBefore(givenDate);
  }
  return false;
};
