import { CannedMessage, CannedMessageResponse } from '@/types';
import { DateFilter } from './date-filter';

export const stopAndRouteCannedMessages = (messageResponseData: CannedMessageResponse[]) => {
  const routes: CannedMessage[] = messageResponseData
    .reduce(
      (accumulatedRoutes: CannedMessage[], currentCannedMessageResponse: CannedMessageResponse) => {
        return [...accumulatedRoutes, ...(currentCannedMessageResponse?.routes || [])];
      },
      []
    )
    ?.filter((route: CannedMessage) => DateFilter(route));

  const stops: CannedMessage[] = messageResponseData
    .reduce(
      (accumulatedStops: CannedMessage[], currentCannedMessageResponse: CannedMessageResponse) => {
        return [...accumulatedStops, ...(currentCannedMessageResponse?.stops || [])];
      },
      []
    )
    ?.filter((stop: CannedMessage) => DateFilter(stop));

  return [routes, stops];
};
