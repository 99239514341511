import alert_bubble from '@/assets/icons/map-controls/alert-bubble.svg';
import attraction from '../assets/icons/other-icons/attraction.svg';
import blue_803_streetcar_horiz from '@/assets/icons/blue-cars/803-horiz.svg';
import blue_803_streetcar_vert from '@/assets/icons/blue-cars/803-vert.svg';
import blue_804_streetcar_horiz from '@/assets/icons/blue-cars/804-horiz.svg';
import blue_804_streetcar_vert from '@/assets/icons/blue-cars/804-vert.svg';
import blue_arrow_down from '@/assets/icons/track-arrows/blue-arrow-down.svg';
import blue_arrow_left from '@/assets/icons/track-arrows/blue-arrow-left.svg';
import blue_arrow_right from '@/assets/icons/track-arrows/blue-arrow-right.svg';
import blue_arrow_up from '@/assets/icons/track-arrows/blue-arrow-up.svg';
import city from '../assets/icons/other-icons/city.svg';
import green_805_streetcar_horiz from '@/assets/icons/green-cars/805-horiz.svg';
import green_805_streetcar_vert from '@/assets/icons/green-cars/805-vert.svg';
import grey_streetcar_horiz from '@/assets/icons/grey_cars/horiz.svg';
import grey_streetcar_vert from '@/assets/icons/grey_cars/vert.svg';
import green_806_streetcar_horiz from '@/assets/icons/green-cars/806-horiz.svg';
import green_806_streetcar_vert from '@/assets/icons/green-cars/806-vert.svg';
import hospital from '../assets/icons/other-icons/hospital.svg';
import info_circle from '@/assets/icons/stop-info/stop-info-circle.svg';
import info_horizontal from '@/assets/icons/stop-info/stop-info-horiz.svg';
import info_vertical from '@/assets/icons/stop-info/stop-info-vert.svg';
import mobile_close_menu_button from '@/assets/icons/map-controls/mobile-close-menu-button.svg';
import mobile_open_menu_button from '@/assets/icons/map-controls/mobile-open-menu-button.svg';
import mobile_open_menu_button_with_alert from '@/assets/icons/map-controls/mobile-open-menu-button-with-alert.svg';
import museum from '../assets/icons/other-icons/museum.svg';
import park from '../assets/icons/other-icons/park.svg';
import parking from '@/assets/icons/other-icons/parking.svg';
import person_walking from '@/assets/icons/person-walking.svg';
import pink_801_streetcar_horiz from '@/assets/icons/pink-cars/801-horiz.svg';
import pink_801_streetcar_vert from '@/assets/icons/pink-cars/801-vert.svg';
import pink_802_streetcar_horiz from '@/assets/icons/pink-cars/802-horiz.svg';
import pink_802_streetcar_vert from '@/assets/icons/pink-cars/802-vert.svg';
import pink_807_streetcar_horiz from '@/assets/icons/pink-cars/807-horiz.svg';
import pink_807_streetcar_vert from '@/assets/icons/pink-cars/807-vert.svg';
import pink_arrow_down from '@/assets/icons/track-arrows/pink-arrow-down.svg';
import pink_arrow_left from '@/assets/icons/track-arrows/pink-arrow-left.svg';
import pink_arrow_right from '@/assets/icons/track-arrows/pink-arrow-right.svg';
import pink_arrow_up from '@/assets/icons/track-arrows/pink-arrow-up.svg';
import santa_fe_depot from '../assets/icons/other-icons/santa-fe-depot.svg';
import spokies from '@/assets/icons/other-icons/spokies.svg';
import transit_center from '@/assets/icons/other-icons/transit-center.svg';
import us_fleet_logo from '@/assets/icons/map-controls/usfleet-logo.svg';
//import user_location from '@/assets/icons/user-location.svg';
import user_location from '@/assets/icons/user-dot.svg';
import water_taxi from '../assets/icons/other-icons/water-taxi.svg';

export const icons = {
  alert_bubble,
  attraction,
  blue_803_streetcar_horiz,
  blue_803_streetcar_vert,
  blue_804_streetcar_horiz,
  blue_804_streetcar_vert,
  blue_arrow_down,
  blue_arrow_left,
  blue_arrow_right,
  blue_arrow_up,
  city,
  green_805_streetcar_horiz,
  green_805_streetcar_vert,
  green_806_streetcar_horiz,
  green_806_streetcar_vert,
  grey_streetcar_horiz,
  grey_streetcar_vert,
  hospital,
  info_circle,
  info_horizontal,
  info_vertical,
  mobile_close_menu_button,
  mobile_open_menu_button,
  mobile_open_menu_button_with_alert,
  museum,
  park,
  parking,
  person_walking,
  pink_801_streetcar_horiz,
  pink_801_streetcar_vert,
  pink_802_streetcar_horiz,
  pink_802_streetcar_vert,
  pink_807_streetcar_horiz,
  pink_807_streetcar_vert,
  pink_arrow_down,
  pink_arrow_left,
  pink_arrow_right,
  pink_arrow_up,
  santa_fe_depot,
  spokies,
  transit_center,
  us_fleet_logo,
  user_location,
  water_taxi,
};
