import { StreetCar } from '@/types';

export const verizon_streetcars: StreetCar[] = [
  {
    streetcar: 201801,
    esn: 5571038066,
  },
  {
    streetcar: 201802,
    esn: 5571038124,
  },
  {
    streetcar: 201803,
    esn: 5571038134,
  },
  {
    streetcar: 201804,
    esn: 5571007702,
  },
  {
    streetcar: 201805,
    esn: 5571006447,
  },
  {
    streetcar: 201806,
    esn: 5571038135,
  },
  {
    streetcar: 201807,
    esn: 5571014803,
  },
];
