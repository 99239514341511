export enum STOP_IDS {
  EAST_BRICKTOWN = 1,
  BALL_PARK = 2,
  SANTA_FE_HUB = 3,
  ARENA = 4,
  SCISSORTAIL_PARK = 5,
  MYRIAD_GARDENS = 6,
  LIBRARY = 7,
  TRANSIT_CENTER = 8,
  FEDERAL_COURTHOUSE = 9,
  BROADWAY_AVENUE = 10,
  AUTOMOBILE_ALLEY = 11,
  ART_PARK = 12,
  NORTH_HUDSON = 13,
  DEWEY_AVENUE = 14,
  MIDTOWN = 15,
  NW_10TH_STREET = 16,
  LAW_SCHOOL = 17,
  MEMORIAL_MUSEUM = 18,
  BUSINESS_DISTRICT = 19,
  CENTRURY_CENTER = 20,
  BRICKTOWN = 21,
  MICKEY_MANTLE = 22,
}
