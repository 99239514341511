<template>
  <g-marker
    v-if="transitCenterMarker"
    :address="transitCenterMarker.address"
    :clickable="true"
    :content="transitCenterMarker.content"
    :draggable="false"
    :icon="transitCenterMarker.icon"
    :opened="transitCenterMarker.opened"
    :position="transitCenterMarker.position"
    :title="transitCenterMarker.title"
    :type="transitCenterMarker.type"
    :zIndex="100"
  >
    <template v-slot="{ marker, map }">
      <info-window v-if="transitCenterMarker.content" :map="map" :marker="marker">
        <div class="marker-window transit-center" v-if="marker?.opened && marker.type === type">
          <div
            v-bind:class="[
              marker.content.googleLink && marker.content.ticketsLink ? 'bottom-margin' : null,
            ]"
          >
            <div class="transit-center-name" v-if="marker.title">
              {{ marker.title }}
            </div>
            <div class="transit-center-address" v-if="marker.address">
              {{ marker.address }}
            </div>
            <div class="transit-center-links-wrapper">
              <div class="transit-center-google-link" v-if="marker.content.googleLink">
                <a target="_blank" :href="marker.content.googleLink">Get Directions</a>
              </div>
              <div class="transit-center-embark-services" v-if="marker.content.embarkServices">
                <a target="_blank" :href="marker.content.embarkServices">Embark Services</a>
              </div>
            </div>
          </div>
        </div>
      </info-window></template
    >
  </g-marker>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import InfoWindow from '@/components/InfoWindow.vue';
import GMarker from '@/components/GMarker.vue';
import { ParkingMarker } from '@/types';
export default defineComponent({
  name: 'Transit Center Marker',
  components: { GMarker, InfoWindow },
  props: {
    transitCenter: Object as PropType<ParkingMarker>,
  },
  setup(props) {
    const transitCenterMarker = ref(props.transitCenter);
    const type = ref(props.transitCenter?.type);
    return { transitCenterMarker, type };
  },
});
</script>

<style scoped lang="scss"></style>
