<template>
  <div class="g-marker">
    <template v-if="Boolean(marker) && Boolean(map)">
      <slot :marker="marker" :map="map" />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, onUnmounted, onMounted, PropType, ref, computed, watch } from 'vue';
import { Content, Icon, Label, LatLng, MarkerType } from '@/types';
import {
  DEFAULT_CENTER,
  fontWeight,
  fontSize,
  POI_COLLECTION,
  UPDATE_PARKING_MARKERS,
  UPDATE_POI_MARKER,
  UPDATE_SPOKIES_MARKERS,
  UPDATE_STOP_MARKER,
  UPDATE_STREET_CAR_MARKER,
  UPDATE_TRANSIT_CENTER_MARKER,
} from '@/utils';
import { useStore } from 'vuex';
import { uuid } from 'vue-uuid';
import { MARKER_TYPE } from '@/enums';
export default defineComponent({
  name: 'GMarker',
  props: {
    address: String,
    clickable: {
      type: Boolean,
      default: true,
    },
    content: Object as PropType<Content>,
    draggable: {
      type: Boolean,
      default: false,
    },
    esn: Number,
    generatedESN: String,
    icon: Object as PropType<Icon>,
    infoWindow: Object as PropType<google.maps.InfoWindow>,
    label: Object as PropType<Label>,
    position: {
      type: Object as PropType<LatLng>,
      required: true,
    },
    opened: { type: Boolean, default: false },
    title: { type: String, default: '' },
    type: {
      type: String as PropType<MarkerType>,
      required: true,
    },
    zIndex: Number,
  },
  setup(props) {
    const store = useStore();
    const google = computed(() => store.getters.google);
    const map = computed(() => store.getters.map);
    const marker = ref();
    const zIndex = props.zIndex ? { zIndex: 1 } : {};
    const config = {
      address: props.address || '',
      content: props.content || undefined,
      draggable: props.draggable,
      icon: props.icon,
      map: map.value,
      opened: props.opened,
      position: props.position || DEFAULT_CENTER,
      title: props.title,

      type: props.type,
      unique_key: uuid.v4(),
      ...zIndex,
    };
    const type = ref(props.type);
    const _clearEvents = () => {
      google.value.maps.event.clearInstanceListeners(marker.value);
    };
    const _destroy = () => {
      _clearEvents();
      marker.value.setMap(null);
    };
    onMounted(async () => {
      marker.value = new google.value.maps.Marker(config);

      if (type.value === MARKER_TYPE.PARKING) {
        store.commit(UPDATE_PARKING_MARKERS, { esn: props.generatedESN, marker: marker.value });
      }

      if (type.value === MARKER_TYPE.SPOKIE) {
        store.commit(UPDATE_SPOKIES_MARKERS, { esn: props.generatedESN, marker: marker.value });
      }

      if (type.value === MARKER_TYPE.STOP) {
        if (store.state.controls.showStopLabel) {
          marker.value.setLabel({ text: props.title || '', fontSize, fontWeight });
        }
        store.commit(UPDATE_STOP_MARKER, {
          esn: props.generatedESN,
          label: props.label?.text,
          marker: marker.value,
        });
      }

      if (type.value === MARKER_TYPE.STREET_CAR) {
        store.commit(UPDATE_STREET_CAR_MARKER, { esn: props.esn, marker: marker.value });
      }

      if (type.value === MARKER_TYPE.TRANSIT_CENTER) {
        // set the label
        marker.value.setLabel({ text: props.label?.text, fontSize: '12px' });

        store.commit(UPDATE_TRANSIT_CENTER_MARKER, {
          ...store.state.transitCenterMarker,
          esn: props.esn,
          label: props.label?.text,
          marker: marker.value,
        });
      }

      if (POI_COLLECTION.includes(type.value)) {
        // set the label
        marker.value.setLabel({ text: props.label?.text, fontSize: '12px' }); // <-- this will disable the POI labels if commented out

        store.commit(UPDATE_POI_MARKER, {
          esn: props.generatedESN,
          label: props.label?.text,
          marker: marker.value,
        });
      }

      marker.value.addListener('click', () => {
        marker.value.set('opened', true);
      });
    });
    onUnmounted(() => {
      _destroy();
    });

    watch(map, () => {
      marker.value.setMap(map.value);
    });
    return { marker, map };
  },
});
</script>

<style scoped lang="scss"></style>
