import axios from 'axios';
import { get } from 'lodash';
import { ActionContext } from 'vuex';
import 'eventsource-polyfill';
import {
  AssetData,
  CannedMessage,
  CannedMessageDate,
  CannedMessageResponse,
  Content,
  Device,
  Duration,
  Icon,
  MessageSwitch,
  State,
  StopMarker,
  StopStation,
  StreetCar,
  StreetCarDeviceESN,
  StreetCarMarker,
} from '@/types';
import {
  ALL_STOPS_API,
  autheticate,
  CANNED_MESSAGES_API,
  createStopMarker,
  directionsHash,
  formatLabel,
  futureDate,
  getAssets,
  getDevices,
  getStreetCarAsset,
  handleError,
  icon_options,
  icons,
  INSERT_STREETCAR_MARKER,
  insertStreetCarMarker,
  LOAD_ICONS,
  LOAD_INSERVICE_STREET_CAR_ESN,
  LOAD_STOP_SPONSORS_AND_STOP_GOOGLE_LINKS,
  LOAD_STOP_MARKERS,
  LOAD_STREET_CAR_ICON_OPTIONS,
  LOAD_STREET_CAR_DEVICE_ESN,
  loadStreetCarDeviceESN,
  LOAD_STREET_CARS,
  LOAD_STYLES,
  LOAD_USER_LOCATION_ICONS,
  loadStops,
  loadStreetCarIconOptions,
  loadStreetCars,
  loadStopSponsorsAndStopGoogleLinks,
  MIN_REASONABLE_SPEED,
  REMOVE_STREET_CAR_MARKER,
  REVENUE_ROUTES,
  serverSentEvent,
  stop_icon_origin,
  stop_icon_anchor,
  stop_label_position,
  streetcarDirection,
  streetCarMaker,
  styles,
  SUBSCRIBE_TO_USFLEET_DATA_STREAM,
  SUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM,
  TICKET_LINK,
  TOKEN_VALID_TIME,
  UNSUBSCRIBE_TO_USFLEET_DATA_STREAM,
  UNSUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM,
  UPDATE_ASSET_DATA,
  UPDATE_SET_TIME,
  UPDATE_TOKEN,
  UPDATE_USER_LOCATION,
  updateSetTime,
  updateStopsMessages,
  updateToken,
  US_FLEET_ETA,
  US_FLEET_LOCATION,
  user_location_icon,
  OPEN_MARKER_WINDOW,
  OPEN_MARKER_WINDOW_KEY,
  UPDATE_STREET_CAR_ICON_AND_POSITION,
  UPDATE_BRICKTOWN_STOP_DURATION,
  UPDATE_DOWNTOWN_STOP_DURATION,
  UPDATE_STOPS_MESSAGE,
  stopAndRouteCannedMessages,
  specificRouteMessages,
  UPDATE_STOP_MESSAGE,
  LOAD_ALERT_MESSAGE,
  LOAD_DOWNTOWN_ALERT_MESSAGE,
  LOAD_BRICKTOWN_ALERT_MESSAGE,
  LOAD_CUSTOM_DISPLAY_MESSAGES,
  CLEAR_ALERT_MESSAGE,
} from '@/utils';

import { sponsorLinks, stops, streetCarOptions, verizon_streetcars } from '@/data';
import { ROUTE_IDS } from '@/enums';

export const actions = {
  autheticate: async ({ commit, dispatch }: ActionContext<State, State>) => {
    try {
      const username = process.env.VUE_APP_USER_NAME;
      const password = process.env.VUE_APP_USER_PASSWORD;
      const authUrl = process.env.VUE_APP_AUTH_URL;

      //request a token
      const tokenResponse = await axios({
        method: 'post',
        url: authUrl,
        auth: {
          username,
          password,
        },
      });

      const token = await tokenResponse.headers.token;
      if (token) {
        commit(UPDATE_TOKEN, tokenResponse.headers.token);
        await dispatch(updateSetTime);
        await dispatch(getAssets);
      }
    } catch (err) {
      handleError(err);
    }
  },
  checkCannedMessages: async ({ commit, dispatch, state }: ActionContext<State, State>) => {
    try {
      const response = await axios.get(CANNED_MESSAGES_API);
      const messageResponseData: CannedMessageResponse[] = response?.data?.data || [];

      // grab the switch messages
      const messageSwitch: MessageSwitch | undefined = messageResponseData?.[0]?.messageSwitch;
      if (messageSwitch?.status) {
        commit(LOAD_CUSTOM_DISPLAY_MESSAGES, {
          status: true,
          customBricktownMessage: messageSwitch?.customBricktownMessage || '',
          customDowntownMessage: messageSwitch?.customDowntownMessage || '',
        });
      } else {
        commit(LOAD_CUSTOM_DISPLAY_MESSAGES, {
          status: false,
          customBricktownMessage: '',
          customDowntownMessage: '',
        });
      }

      // check if there is a us fleet message
      const usFleetMessage = messageResponseData?.[0]?.usftMessage;

      // Also get the expirations data
      const usFleetMessageEpiration: CannedMessageDate | undefined =
        messageResponseData?.[0]?.usftMessageExpire;

      // clear messages
      commit(UPDATE_STOPS_MESSAGE, '');
      commit(CLEAR_ALERT_MESSAGE);
      // if cannedMessages.usftMessage && now if before cannedMessages.usftMessageExpire
      if (usFleetMessage && futureDate(usFleetMessageEpiration?.date)) {
        // all stops
        commit(LOAD_ALERT_MESSAGE, usFleetMessage);
      }

      // routes and stops logic
      const [routes, stops] = stopAndRouteCannedMessages(messageResponseData);
      const [bricktownRoutes, downtownRoutes] = specificRouteMessages(routes);

      // Write route messages
      // Downtown first, bricktown overwrites down down
      const downtownMessage = downtownRoutes?.cannedMessage;
      // if downtown, update all stops
      if (downtownMessage) {
        commit(LOAD_DOWNTOWN_ALERT_MESSAGE, downtownMessage);
        // all stops
        dispatch(updateStopsMessages, downtownMessage);
      }

      const bricktownMessage = bricktownRoutes?.cannedMessage;
      // if bricktown, update all bricktown, overwrite downtown
      if (bricktownMessage) {
        commit(LOAD_BRICKTOWN_ALERT_MESSAGE, bricktownMessage);

        // bircktown stop stops
        state.stops
          ?.filter((stop: StopMarker) => {
            if (stop?.routeIds) {
              return stop.routeIds.includes(ROUTE_IDS.BRICKTOWN);
            }
          })
          .forEach((stop: StopMarker) =>
            commit(UPDATE_STOP_MESSAGE, {
              stopId: stop.id,
              message: bricktownMessage,
            })
          );
      }

      // Stops overwrite everything
      stops.forEach((stopMessage: CannedMessage) => {
        commit(UPDATE_STOP_MESSAGE, {
          stopId: stopMessage.stopId,
          message: stopMessage.cannedMessage,
        });
      });
    } catch (err) {
      handleError(err);
    }
  },
  clearWindowInfo: async ({ commit }: ActionContext<State, State>) => {
    try {
      commit(OPEN_MARKER_WINDOW, null);
      commit(OPEN_MARKER_WINDOW_KEY, undefined);
    } catch (err) {
      handleError(err);
    }
  },
  getAssets: async ({ state, commit, dispatch }: ActionContext<State, State>) => {
    try {
      const token = state.token;
      if (token) {
        const assetUrl = process.env.VUE_APP_ASSET_URL;

        // request assets
        const assetResponse = await axios({
          method: 'get',
          url: assetUrl,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const assets = await assetResponse.data;

        // added, get devices
        dispatch(getDevices, assets);
        commit(UPDATE_ASSET_DATA, assets);
      }
    } catch (err) {
      handleError(err);
    }
  },
  getDevices: async (
    { state, commit, dispatch }: ActionContext<State, State>,
    assets: AssetData[]
  ) => {
    try {
      const token = state.token;
      if (token) {
        const devicesUrl = process.env.VUE_APP_DEVICE_URL;

        // request devices
        const deviceResponse = await axios({
          method: 'get',
          url: devicesUrl,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const freshDevices = await deviceResponse.data;

        // backup, if we have no fresh devices and the state is empty, we manually use the data file
        if (!state.streetcarDeviceESN && !freshDevices) {
          await dispatch(loadStreetCarDeviceESN, verizon_streetcars);
          await dispatch(loadStreetCars);
        } else if (!state.streetcarDeviceESN && freshDevices) {
          // get active street cars mapped with enabled esn
          const streetcars = assets
            .map((asset: AssetData) => {
              const deviceIds = asset.deviceIds || [];
              const devices = freshDevices.filter((freshDevice: Device) =>
                deviceIds.includes(freshDevice.id)
              );
              const enabledDevice = devices.find((device: Device) => device.enabled);
              if (asset?.show && asset?.customId && enabledDevice?.esn) {
                return { streetcar: +asset.customId, esn: +enabledDevice?.esn };
              }
              return;
            })
            .filter((streetCarDeviceESN?: StreetCarDeviceESN) => streetCarDeviceESN);

          await dispatch(loadStreetCarDeviceESN, streetcars);
          await dispatch(loadStreetCars);
        } else if (freshDevices) {
          const newStreetcars = assets
            .map((asset: AssetData) => {
              const deviceIds = asset.deviceIds || [];
              const devices = freshDevices.filter((freshDevice: Device) =>
                deviceIds.includes(freshDevice.id)
              );
              const enabledDevice = devices.find((device: Device) => device.enabled);

              if (asset?.show && asset?.customId && enabledDevice?.esn) {
                return { streetcar: +asset.customId, esn: +enabledDevice?.esn };
              }
              return;
            })
            .filter((streetCarDeviceESN?: StreetCarDeviceESN) => streetCarDeviceESN);

          // let replaceStreetcarDeviceESN = false;
          const oldStreetCarESNs = state.streetcarDeviceESN;

          // compare if the streetcars data changed
          for (const oldStreetCarESN of oldStreetCarESNs) {
            const foundNewStreetCar = newStreetcars.find(
              newStreetCar => newStreetCar && newStreetCar.streetcar === oldStreetCarESN.streetcar
            );
            // if the new esn has changed, load
            if (!foundNewStreetCar || +foundNewStreetCar.esn !== +oldStreetCarESN.esn) {
              const oldStreetCarMarker = state.streetCarMarkers[oldStreetCarESN.esn];
              oldStreetCarMarker?.marker?.setMap(null);
              oldStreetCarMarker?.marker?.setVisible(false);
              // reload street cars
              await dispatch(loadStreetCarDeviceESN, newStreetcars);
              await dispatch(loadStreetCars);
            }
          }
        }
      }
    } catch (err) {
      handleError(err);
    }
  },
  init: async ({ dispatch }: ActionContext<State, State>) => {
    try {
      await dispatch(updateToken);
      await dispatch(loadStreetCarIconOptions);
      // // this comes before the stops, because the stops use the sponsors
      await dispatch(loadStopSponsorsAndStopGoogleLinks);
      await dispatch(loadStops);
    } catch (err) {
      handleError(err);
    }
  },
  insertStreetCarMarker: async (
    { commit }: ActionContext<State, State>,
    streetcar_marker: StreetCarMarker
  ) => {
    try {
      commit(INSERT_STREETCAR_MARKER, streetcar_marker);
    } catch (err) {
      handleError(err);
    }
  },
  loadIcons: async ({ commit }: ActionContext<State, State>) => {
    try {
      commit(LOAD_ICONS, icons);
    } catch (err) {
      handleError(err);
    }
  },
  loadMapStyles: async ({ commit }: ActionContext<State, State>) => {
    try {
      commit(LOAD_STYLES, styles);
    } catch (err) {
      handleError(err);
    }
  },
  loadStops: async ({ commit, state }: ActionContext<State, State>) => {
    try {
      const stopMarkers: StopMarker[] = stops
        .filter((stop: StopMarker) => stop.id < 23)
        .map((stop: StopMarker) => {
          const icon: Icon = {
            url: state?.icons?.info_circle || '',
            labelOrigin: stop_label_position(stop.number),
            origin: stop_icon_origin(stop.number),
            anchor: stop_icon_anchor(stop.number),
          };

          const content: Content = {
            stopId: stop.id,
            stopName: stop.name,
            sponsor:
              state.sponsorsAndGoogleLink && state.sponsorsAndGoogleLink[stop.id]
                ? state.sponsorsAndGoogleLink[stop.id].sponsor
                : null,
            sponsorLink:
              state.sponsorsAndGoogleLink && state.sponsorsAndGoogleLink[stop.id]
                ? state.sponsorsAndGoogleLink[stop.id].sponsorLink
                : null,
            opened: false,
            title: formatLabel(stop.name).text,
            googleLink: state.sponsorsAndGoogleLink[stop.id].googleLink,
            alertMessage: '',
            ticketLink: TICKET_LINK,
            showAlert: false,
          };
          return createStopMarker(stop, content, icon);
        });

      commit(LOAD_STOP_MARKERS, stopMarkers);
    } catch (err) {
      handleError(err);
    }
  },
  loadStopSponsorsAndStopGoogleLinks: async ({ commit }: ActionContext<State, State>) => {
    try {
      const response = await axios.get(ALL_STOPS_API);

      const stopSponsorsData = await response.data;

      const sponsorsAndGoogleLinks: { [key: string]: StopStation } = {};
      stopSponsorsData.data.forEach((stopSponsor: StopStation) => {
        const foundSponsorLink = sponsorLinks.stops.find(
          (stop: StopStation) => stop.stopId === stopSponsor.stopId
        );

        if (stopSponsor?.stopId) {
          sponsorsAndGoogleLinks[stopSponsor.stopId] = {
            sponsor: stopSponsor.sponsorName,
            uuid: stopSponsor.uuid,
            sponsorLink: foundSponsorLink?.sponsorLink || undefined,
            googleLink: foundSponsorLink?.googleLink,
          };
        }
      });
      commit(LOAD_STOP_SPONSORS_AND_STOP_GOOGLE_LINKS, sponsorsAndGoogleLinks);
    } catch (err) {
      handleError(err);
    }
  },
  loadStreetCarDeviceESN: async (
    { commit }: ActionContext<State, State>,
    streetCarDeviceESN: StreetCarDeviceESN
  ) => {
    try {
      commit(LOAD_STREET_CAR_DEVICE_ESN, streetCarDeviceESN);
    } catch (err) {
      handleError(err);
    }
  },
  loadStreetCarIconOptions: async ({ commit, state: { icons } }: ActionContext<State, State>) => {
    try {
      if (icons) {
        const blue = { ...streetCarOptions.blue_options };
        const green = { ...streetCarOptions.green_options };
        const grey = { ...streetCarOptions.grey_options };
        const pink = { ...streetCarOptions.pink_options };

        // grey vert
        const vertical = {
          ...grey.vertical,
          url: icons.grey_streetcar_vert,
        };

        // grey horiz
        const horizontal = {
          ...grey.horizontal,
          url: icons.grey_streetcar_horiz,
        };

        const vertical201801 = {
          ...pink.vertical201801,
          url: icons.pink_801_streetcar_vert,
        };

        const horizontal201801 = {
          ...pink.horizontal201801,
          url: icons.pink_801_streetcar_horiz,
        };

        const vertical201802 = {
          ...pink.vertical201802,
          url: icons.pink_802_streetcar_vert,
        };

        const horizontal201802 = {
          ...pink.horizontal201802,
          url: icons.pink_802_streetcar_horiz,
        };

        const vertical201807 = {
          ...pink.vertical201807,
          url: icons.pink_807_streetcar_vert,
        };

        const horizontal201807 = {
          ...pink.horizontal201807,
          url: icons.pink_807_streetcar_horiz,
        };

        const vertical201803 = {
          ...blue.vertical201803,
          url: icons.blue_803_streetcar_vert,
        };

        const horizontal201803 = {
          ...blue.horizontal201803,
          url: icons.blue_803_streetcar_horiz,
        };

        const vertical201804 = {
          ...blue.vertical201804,
          url: icons.blue_804_streetcar_vert,
        };

        const horizontal201804 = {
          ...blue.horizontal201804,
          url: icons.blue_804_streetcar_horiz,
        };

        const vertical201805 = {
          ...green.vertical201805,
          url: icons.green_805_streetcar_vert,
        };

        const horizontal201805 = {
          ...green.horizontal201805,
          url: icons.green_805_streetcar_horiz,
        };

        const vertical201806 = {
          ...green.vertical201806,
          url: icons.green_806_streetcar_vert,
        };

        const horizontal201806 = {
          ...green.horizontal201806,
          url: icons.green_806_streetcar_horiz,
        };

        commit(LOAD_STREET_CAR_ICON_OPTIONS, {
          blue_options: {
            vertical201803,
            horizontal201803,
            vertical201804,
            horizontal201804,
          },
          green_options: {
            vertical201805,
            horizontal201805,
            vertical201806,
            horizontal201806,
          },
          pink_options: {
            vertical201801,
            horizontal201801,
            vertical201802,
            horizontal201802,
            vertical201807,
            horizontal201807,
          },
          grey_options: {
            vertical,
            horizontal,
          },
        });
      }
    } catch (err) {
      handleError(err);
    }
  },
  loadStreetCars: async ({ commit, state }: ActionContext<State, State>) => {
    try {
      const formatted_streetcars = state.streetcarDeviceESN.map(
        (streetCarDeviceESN: StreetCarDeviceESN) => {
          const streetcardNumber = get(streetCarDeviceESN, 'streetcar');
          const option = icon_options(streetcardNumber);
          const foundAsset = Array.isArray(state.assetData)
            ? state.assetData.find(asset => parseInt(asset?.customId || '0') == +streetcardNumber)
            : undefined;

          const routeId = foundAsset ? get(foundAsset, 'routeId') : null;

          if (option && routeId && REVENUE_ROUTES.indexOf(routeId) != -1) {
            return {
              ...streetCarDeviceESN,
              icon: state.streetcar_icon_options
                ? state.streetcar_icon_options[option][`vertical${streetCarDeviceESN.streetcar}`]
                : null,
              icon_options: state.streetcar_icon_options
                ? { ...state.streetcar_icon_options[option] }
                : null,
            };
          } else {
            return {
              ...streetCarDeviceESN,
              icon: state.streetcar_icon_options
                ? state.streetcar_icon_options['grey_options'][`vertical`] // TODO figure out how to determine the init direction
                : null,
              icon_options: state.streetcar_icon_options
                ? { ...state.streetcar_icon_options['grey_options'] }
                : null,
            };
          }
        }
      );

      //Store the inservice esn and then store street cars
      const esns = formatted_streetcars.map((car: StreetCar) => car.esn);
      commit(LOAD_INSERVICE_STREET_CAR_ESN, esns);
      commit(LOAD_STREET_CARS, formatted_streetcars);
    } catch (err) {
      handleError(err);
    }
  },
  loadUserLocationIcon: async ({ commit }: ActionContext<State, State>) => {
    try {
      commit(LOAD_USER_LOCATION_ICONS, user_location_icon);
    } catch (err) {
      handleError(err);
    }
  },
  subscribeToUsfleetDataStream: async ({
    commit,
    state,
    dispatch,
  }: ActionContext<State, State>) => {
    try {
      const connection = await serverSentEvent(US_FLEET_LOCATION, {
        format: 'json',
      }); // omit for no format pre-processing

      // Catch any errors (ie. lost connections, etc.)
      //eslint-disable-next-line
      connection.onError((e: any) => {
        //eslint-disable-next-line
        console.error('lost connection; giving up!', e);

        // This is purely for example; EventSource will automatically
        // attempt to reconnect indefinitely, with no action needed
        // on your part to resubscribe to events once (if) reconnected
        connection.close();
        commit(UNSUBSCRIBE_TO_USFLEET_DATA_STREAM);
      });

      // Subscribe to US fleet
      commit(SUBSCRIBE_TO_USFLEET_DATA_STREAM, connection);

      // Listen for messages without a specified event
      //eslint-disable-next-line
      connection.subscribe('', async (data: any) => {
        // Token expires every TOKEN_VALID_TIME so, we update it
        const lapsedTime = state.setTime ? +Date.now() - state.setTime : +Date.now();

        if (lapsedTime > TOKEN_VALID_TIME) {
          dispatch(updateToken);
        }

        // only run if they belong to the allowed street cars
        if (state.inServiceStreetCarESN.includes(data.esn)) {
          const existingStreetCarMarker = state.streetCarMarkers[data.esn];
          // check if its already inserted
          if (existingStreetCarMarker) {
            const existingStreetCarAsset: AssetData | undefined = getStreetCarAsset(
              state.assetData,
              existingStreetCarMarker
            );

            if (existingStreetCarAsset?.service) {
              const currentDirection = streetcarDirection(data.heading);

              if (
                existingStreetCarMarker.esn &&
                directionsHash[existingStreetCarMarker.esn].prev === currentDirection &&
                data.speed > MIN_REASONABLE_SPEED
              ) {
                directionsHash[existingStreetCarMarker.esn].rVal = currentDirection;
              }

              if (existingStreetCarMarker.esn && data.speed > MIN_REASONABLE_SPEED) {
                directionsHash[existingStreetCarMarker.esn].prev = currentDirection;
              }
              let icon;
              if (existingStreetCarMarker.esn) {
                icon = existingStreetCarMarker?.icon_options
                  ? existingStreetCarMarker?.icon_options?.[
                      `${directionsHash[existingStreetCarMarker.esn].rVal}${
                        existingStreetCarMarker.streetcar
                      }`
                    ]
                  : state.streetcar_icon_options['grey_options'][
                      directionsHash[existingStreetCarMarker.esn || ''].rVal
                    ] || undefined;
              }

              const position = {
                lat: data.latitude,
                lng: data.longitude,
              };
              if (!icon) {
                icon =
                  state.streetcar_icon_options['grey_options'][
                    directionsHash[existingStreetCarMarker.esn || ''].rVal
                  ];
              }
              commit(UPDATE_STREET_CAR_ICON_AND_POSITION, {
                esn: existingStreetCarMarker.esn,
                icon,
                position,
              });
            } else {
              // nix the streetcar
              commit(REMOVE_STREET_CAR_MARKER, existingStreetCarMarker);
            }
          } else {
            // look it up from the loaded street cars
            const foundStreetCar: StreetCar | undefined = state.streetcars?.find(
              (streetcar: StreetCar) => streetcar.esn === data.esn
            );

            if (foundStreetCar) {
              // get the assets and load them up
              const streetCarAsset: AssetData | undefined = getStreetCarAsset(
                state.assetData,
                foundStreetCar
              );

              // don't proceed if its not in service
              if (!streetCarAsset?.service) {
                return; // ignore
              }

              const start_direction = streetcarDirection(data.heading);
              directionsHash[foundStreetCar.esn] = {
                rVal: start_direction,
                prev: start_direction,
              };

              const createdStreetCarMarker = streetCarMaker(
                streetCarAsset,
                foundStreetCar,
                start_direction,
                { ...data, streetcar_options: state.streetcar_icon_options }
              );

              if (REVENUE_ROUTES.indexOf(streetCarAsset.routeId) != -1) {
                // revenue streetcars
                dispatch(insertStreetCarMarker, createdStreetCarMarker);
              } else {
                // none revenue streetcars
                dispatch(insertStreetCarMarker, {
                  ...createdStreetCarMarker,
                  nonRevenue: 'non-revenue',
                });
              }
            }
          }
        }
      });
    } catch (err) {
      handleError(err);
      commit(UNSUBSCRIBE_TO_USFLEET_DATA_STREAM);
    }
  },
  subscribeToUsfleetStopsDataStream: async ({ commit, state }: ActionContext<State, State>) => {
    try {
      const stops_connection = await serverSentEvent(US_FLEET_ETA, {
        format: 'json',
      }); // omit for no format pre-processing

      // Catch any errors (ie. lost connections, etc.)
      //eslint-disable-next-line
      stops_connection.onError((e: any) => {
        //eslint-disable-next-line
        console.error('lost stops connection; giving up!', e);

        // This is purely for example; EventSource will automatically
        // attempt to reconnect indefinitely, with no action needed
        // on your part to resubscribe to events once (if) reconnected
        stops_connection.close();
        commit(UNSUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM);
      });

      // Listen for messages without a specified event
      //eslint-disable-next-line
      stops_connection.subscribe('', async (data: any) => {
        const foundStop = state?.stops?.find(stop => stop.id === data.stopId);

        if (foundStop) {
          const stopDurations: Duration = {
            durations: [data.firstDuration, data.secondDuration, data.thirdDuration],
            routeName: data.routeName,
            routeId: data.routeId,
            stopName: data.stopName,
            stopId: data.stopId,
          };

          // New way, puts it in the state
          if (data.routeName === 'Bricktown') {
            commit(UPDATE_BRICKTOWN_STOP_DURATION, stopDurations);
          } else if (data.routeName === 'Downtown') {
            commit(UPDATE_DOWNTOWN_STOP_DURATION, stopDurations);
          }
        }
      });

      commit(SUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM, stops_connection);
    } catch (err) {
      handleError(err);
      commit(UNSUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM);
    }
  },
  unSubscribeToUsfleetDataStream: async ({ commit, state }: ActionContext<State, State>) => {
    try {
      if (state.usFleetSubscription != null) {
        console.log('unsubscirbe us fleet stream');
        state.usFleetSubscription.close();
        commit(UNSUBSCRIBE_TO_USFLEET_DATA_STREAM);
        // TODO, go through makrers and clear them
      }
    } catch (err) {
      handleError(err);
    }
  },
  unSubscribeToUsfleetStopsDataStream: async ({ commit, state }: ActionContext<State, State>) => {
    try {
      if (state.usFleetStopsSubscription != null) {
        console.log('unsubscirbe data stream');
        state.usFleetStopsSubscription.close();
        commit(UNSUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM);
      }
    } catch (err) {
      handleError(err);
    }
  },
  updateSetTime: async ({ commit }: ActionContext<State, State>) => {
    try {
      commit(UPDATE_SET_TIME, +Date.now());
    } catch (err) {
      handleError(err);
    }
  },
  updateStopsMessages: async ({ commit }: ActionContext<State, State>, message: string) => {
    commit(UPDATE_STOPS_MESSAGE, message);
  },
  updateUserLocation: async ({ commit }: ActionContext<State, State>) => {
    try {
      /*
        const google = state.google;
      if (navigator.geolocation && google) {
        navigator.geolocation.getCurrentPosition(position => {
          const newLatLng = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );
          commit(UPDATE_USER_LOCATION, newLatLng);
        });
      }
      */
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const new_position = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          commit(UPDATE_USER_LOCATION, new_position);
        });
      }
    } catch (err) {
      handleError(err);
    }
  },
  updateWindowInfoStatus: async (
    { commit }: ActionContext<State, State>,
    { key = undefined, infoWindow }: { key?: string; infoWindow: google.maps.InfoWindow }
  ) => {
    try {
      commit(OPEN_MARKER_WINDOW, infoWindow);
      commit(OPEN_MARKER_WINDOW_KEY, key);
    } catch (err) {
      handleError(err);
    }
  },
  updateToken: async ({ dispatch }: ActionContext<State, State>) => {
    try {
      await dispatch(autheticate);
    } catch (err) {
      handleError(err);
    }
  },
};
