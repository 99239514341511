
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import alertBubble from '../assets/icons/map-controls/alert-bubble.svg';
import mobileCloseMenuButton from '../assets/icons/map-controls/mobile-close-menu-button.svg';
import mobileOpenMenuButton from '../assets/icons/map-controls/mobile-open-menu-button.svg';
import mobileOpenMenuButtonWithAlert from '../assets/icons/map-controls/mobile-open-menu-button-with-alert.svg';
import okcStreetcar from '../assets/icons/map-controls/okc-streetcar-logo.svg';
import usFleetLogo from '../assets/icons/map-controls/usfleet-logo.svg';
import {
  CHECK_STREET_KEY,
  hideRoadsAndLabels,
  showRoadsAndLabels,
  TOGGLE_MOBILE_LEGEND,
  TOGGLE_STREETS,
  toggleCheckStreets,
  toggleCheckParking,
  toggleCheckSpokies,
  STOP_LABEL_KEY,
  TOGGLE_STOP_LABELS,
  toggleStopLabel,
  toggleShowPOI,
  toggleCheckTransitCenter,
} from '@/utils';
export default defineComponent({
  name: 'Map Control',
  setup(props) {
    const store = useStore();

    // state
    const alert_bubble = ref(alertBubble);
    const alertMessage = computed(() => store.state.alertMessage);
    const beta_release = ref(true);
    const mobile_close_menu_button = ref(mobileCloseMenuButton);
    const mobile_open_menu_button = ref(mobileOpenMenuButton);
    const mobile_open_menu_button_with_alert = ref(mobileOpenMenuButtonWithAlert);
    const okc_streetcar = ref(okcStreetcar);
    const us_fleet_logo = ref(usFleetLogo);

    // computed properties
    const parking = computed({
      get: () => store.state.controls.checkParking,
      set: (value: boolean) => store.dispatch(toggleCheckParking, value),
    });

    const show_mobile_legend = computed({
      get: () => store.state.openMobileLegend,
      set: (value: boolean) => store.commit(TOGGLE_MOBILE_LEGEND, value),
    });

    const showPOI = computed({
      get: () => store.state.controls.showPOI,
      set: (value: boolean) => store.dispatch(toggleShowPOI, value),
    });

    const spokies = computed({
      get: () => store.state.controls.checkSpokies,
      set: (value: boolean) => {
        store.dispatch(toggleCheckSpokies, value);
      },
    });

    const stop_labels = computed({
      get: () => store.state.controls.showStopLabel,
      set: (value: boolean) => store.dispatch(toggleStopLabel, value),
    });

    const streets = computed({
      get: () => store.state.controls.checkStreets,
      set: (value: boolean) => store.dispatch(toggleCheckStreets, value),
    });

    const transitCenter = computed({
      get: () => store.state.controls.checkTransitCenter,
      set: (value: boolean) => store.dispatch(toggleCheckTransitCenter, value),
    });

    return {
      alert_bubble,
      alertMessage,
      beta_release,
      mobile_close_menu_button,
      mobile_open_menu_button,
      mobile_open_menu_button_with_alert,
      okc_streetcar,
      parking,
      us_fleet_logo,
      show_mobile_legend,
      showPOI,
      spokies,
      stop_labels,
      streets,
      transitCenter,
    };
  },
});
