import { parking, poi, spokies, transitCenter } from '@/data';
import { MARKER_TYPE, VISIBILITY } from '@/enums';
import { MapTypeStyle, ParkingMarker, POIMarker, SpokieMarker, State } from '@/types';
import {
  CHECK_PARKING_KEY,
  CHECK_SPOKIE_KEY,
  CHECK_STREET_KEY,
  CHECK_TRANSIT_CENTER_KEY,
  DEFAULT_CHECK_PARKING,
  DEFAULT_CHECK_SPOKIES,
  DEFAULT_CHECK_STREET,
  DEFAULT_CHECK_TRANSIT_CENTER,
  DEFAULT_SHOW_POI,
  DEFAULT_SHOW_STOP_LABEL,
  fontWeight,
  fontSize,
  getPoiIcon,
  handleError,
  hideParking,
  hidePOI,
  hideRoadsAndLabels,
  hideSpokies,
  hideTransitCenter,
  icons,
  initializeParking,
  initializePOI,
  initializeSpokies,
  initializeTransitCenterMarker,
  INSERT_PARKING_MARKERS,
  INSERT_POI_MARKERS,
  INSERT_SPOKIES_MARKERS,
  INSERT_TRANSIT_CENTER_MARKER,
  LOAD_MAP_OPTIONS,
  LOAD_STYLES,
  poiStyles,
  poi_label_position,
  reloadMapOptionsAndStyles,
  roadStyles,
  showParking,
  showPOI,
  showRoadsAndLabels,
  showSpokies,
  showTransitCenter,
  SHOW_POI_KEY,
  STOP_LABEL_KEY,
  toggleCheckTransitCenter,
  TOGGLE_PARKING,
  TOGGLE_POINT_OF_INTEREST,
  TOGGLE_SPOKIES,
  TOGGLE_STOP_LABELS,
  TOGGLE_STREETS,
  TOGGLE_TRANSIT_CENTER,
  mapOptions,
} from '@/utils';
import { uuid } from 'vue-uuid';
import { ActionContext } from 'vuex';

export const controls = {
  actions: {
    hideParking: async ({ rootState, state }: ActionContext<State, State>) => {
      try {
        if (state.checkParking === false) {
          Object.keys(rootState.parkingMarkers).forEach((key: string) => {
            rootState.parkingMarkers[key].marker?.setVisible(false);
          });
        }
      } catch (err) {
        handleError(err);
      }
    },
    hidePOI: async ({ dispatch, rootState, state }: ActionContext<State, State>) => {
      try {
        // hide overlay
        rootState?.poiOverlay?.setOpacity(0);
        if (!state.showPOI) {
          Object.keys(rootState.poiMarkers).forEach((key: string) => {
            rootState.poiMarkers[key].marker?.setVisible(false);
          });
        }

        const POIRoadsAndLabels: MapTypeStyle[] = state.checkStreets
          ? []
          : [
              {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'road',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
              },
            ];

        //console.log(POIRoadsAndLabels, state.checkStreets);

        if (rootState.mapOptions && rootState?.map && !state.showPOI) {
          // const styles: MapTypeStyle[] = poiStyles(rootState?.mapOptions?.styles);
          rootState.map?.setOptions({
            styles: [...(rootState?.mapOptions?.styles || []), ...POIRoadsAndLabels],
          });
          // dispatch(reloadMapOptionsAndStyles, styles);
        }
      } catch (err) {
        handleError(err);
      }
    },
    hideRoadsAndLabels: async ({ dispatch, rootState, state }: ActionContext<State, State>) => {
      try {
        const POIRoadsAndLabels: MapTypeStyle[] = state.showPOI
          ? [
              {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'road',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [
                  {
                    visibility: 'simplified',
                  },
                ],
              },
            ]
          : [
              {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'road',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
              },
            ];

        if (rootState.mapOptions && rootState?.map && !state.checkStreets) {
          // const styles: MapTypeStyle[] = roadStyles(rootState.mapOptions, VISIBILITY.OFF);
          rootState.map?.setOptions({
            styles: [...(rootState?.mapOptions?.styles || []), ...POIRoadsAndLabels],
          });
          //dispatch(reloadMapOptionsAndStyles, styles);
        }
      } catch (err) {
        handleError(err);
      }
    },
    hideSpokies: async ({ rootState, state }: ActionContext<State, State>) => {
      try {
        if (state.checkSpokies === false) {
          Object.keys(rootState.spokiesMarkers).forEach((key: string) => {
            rootState.spokiesMarkers[key].marker?.setVisible(false);
          });
        }
      } catch (err) {
        handleError(err);
      }
    },
    hideTransitCenter: async ({ rootState, state }: ActionContext<State, State>) => {
      try {
        if (state.checkTransitCenter === false) {
          rootState?.transitCenterMarker?.marker?.setVisible(false);
        }
      } catch (err) {
        handleError(err);
      }
    },
    initialMapControls: async ({ dispatch, state }: ActionContext<State, State>) => {
      try {
        // parking
        await dispatch(initializeParking);
        if (state.checkParking) {
          await dispatch(showParking);
        } else {
          await dispatch(hideParking);
        }

        // POI
        await dispatch(initializePOI);
        if (state.showPOI) {
          await dispatch(showPOI);
        } else {
          await dispatch(hidePOI);
        }

        // spokies - loading occurs here, but hidden for now
        /*
        await dispatch(initializeSpokies);
        if (state.checkSpokies) {
          await dispatch(showSpokies);
        } else {
          await dispatch(hideSpokies);
        }
        */

        // streets
        if (state.checkStreets) {
          await dispatch(showRoadsAndLabels);
        } else {
          await dispatch(hideRoadsAndLabels);
        }

        // Transit Center
        await dispatch(initializeTransitCenterMarker);
        if (state.checkTransitCenter) {
          await dispatch(showTransitCenter);
        } else {
          await dispatch(hideTransitCenter);
        }
      } catch (err) {
        handleError(err);
      }
    },
    initializeParking: async ({ commit, state }: ActionContext<State, State>) => {
      try {
        const parkingMarkers: ParkingMarker[] = parking?.map((parkingItem: ParkingMarker) => {
          return {
            esn: uuid.v4(),
            title: parkingItem.name,
            address: parkingItem.address,
            position: parkingItem.position,
            icon: {
              url: icons?.parking || '',
              labelOrigin: parkingItem?.labelOrigin,
              origin: parkingItem.iconOrigin,
              anchor: parkingItem.iconAnchor,
            },
            type: MARKER_TYPE.PARKING,
            content: {
              googleLink: parkingItem.googleLink,
              opened: false,
            },
          };
        });
        commit(INSERT_PARKING_MARKERS, parkingMarkers);
      } catch (err) {
        handleError(err);
      }
    },
    initializePOI: async ({ commit, dispatch }: ActionContext<State, State>) => {
      try {
        // await dispatch(initializeTransitCenterMarker);
        const poiMarkers: POIMarker[] = poi?.map((poiItem: POIMarker) => {
          return {
            esn: uuid.v4(),
            title: poiItem.title,
            position: poiItem.position,
            address: poiItem.address,
            label: poiItem.label,
            icon: {
              url: getPoiIcon(poiItem.type || MARKER_TYPE.CITY),
              labelOrigin: poi_label_position(poiItem?.id),
              origin: poiItem.iconOrigin,
              anchor: poiItem.iconAnchor,
            },

            type: poiItem?.type || MARKER_TYPE.CITY,
            content: {
              googleLink: poiItem.googleLink,
              opened: false,
            },
          };
        });
        commit(INSERT_POI_MARKERS, poiMarkers);
      } catch (err) {
        handleError(err);
      }
    },
    initializeSpokies: async ({ commit }: ActionContext<State, State>) => {
      try {
        const spokieMakrers: SpokieMarker[] = spokies?.map((spokie: SpokieMarker) => {
          return {
            esn: uuid.v4(),
            title: spokie.name,
            position: spokie.position,
            address: spokie.address,
            icon: {
              url: icons?.spokies || '',
              labelOrigin: spokie.labelOrigin,
              origin: spokie.iconOrigin,
              anchor: spokie.iconAnchor,
            },
            type: MARKER_TYPE.SPOKIE,
            content: {
              googleLink: spokie.googleLink,
              opened: false,
            },
          };
        });
        commit(INSERT_SPOKIES_MARKERS, spokieMakrers);
      } catch (err) {
        handleError(err);
      }
    },
    initializeTransitCenterMarker: async ({ commit }: ActionContext<State, State>) => {
      try {
        commit(INSERT_TRANSIT_CENTER_MARKER, {
          esn: uuid.v4(),
          title: transitCenter.name,
          position: transitCenter.position,
          address: transitCenter.address,
          icon: {
            url: icons?.transit_center || '',
            labelOrigin: transitCenter?.labelOrigin,
            origin: transitCenter?.iconOrigin,
            anchor: transitCenter?.iconAnchor,
          },
          type: MARKER_TYPE.TRANSIT_CENTER,
          content: {
            googleLink: transitCenter.googleLink,
            embarkServices: transitCenter.embarkServices,
            opened: false,
          },
        });
      } catch (err) {
        handleError(err);
      }
    },
    loadLocalStorage: async ({ commit }: ActionContext<State, State>) => {
      try {
        const checkParking = JSON.parse(
          localStorage?.getItem(CHECK_PARKING_KEY) || `${DEFAULT_CHECK_PARKING}`
        );
        const checkSpokies = JSON.parse(
          localStorage?.getItem(CHECK_SPOKIE_KEY) || `${DEFAULT_CHECK_SPOKIES}`
        );
        const checkStreets = JSON.parse(
          localStorage?.getItem(CHECK_STREET_KEY) || `${DEFAULT_CHECK_STREET}`
        );
        const checkTransitCenter = JSON.parse(
          localStorage?.getItem(CHECK_TRANSIT_CENTER_KEY) || `${DEFAULT_CHECK_TRANSIT_CENTER}`
        );
        const showPOI = JSON.parse(localStorage?.getItem(SHOW_POI_KEY) || `${DEFAULT_SHOW_POI}`);
        const stopLabels = JSON.parse(
          localStorage?.getItem(STOP_LABEL_KEY) || `${DEFAULT_SHOW_STOP_LABEL}`
        );

        if (checkParking === false || checkParking === true) {
          commit(TOGGLE_PARKING, checkParking);
        }

        if (checkSpokies === false || checkSpokies === true) {
          commit(TOGGLE_SPOKIES, checkSpokies);
        }

        if (checkStreets === false || checkStreets === true) {
          commit(TOGGLE_STREETS, checkStreets);
        }

        if (checkTransitCenter === false || checkTransitCenter === true) {
          commit(TOGGLE_TRANSIT_CENTER, checkTransitCenter);
        }

        if (showPOI === false || showPOI === true) {
          commit(TOGGLE_POINT_OF_INTEREST, showPOI);
        }

        if (stopLabels === false || stopLabels === true) {
          commit(TOGGLE_STOP_LABELS, stopLabels);
        }
      } catch (err) {
        handleError(err);
      }
    },
    reloadMapOptionsAndStyles: async (
      { commit, rootState }: ActionContext<State, State>,
      styles: MapTypeStyle[]
    ) => {
      try {
        // commit(LOAD_MAP_OPTIONS, { ...rootState.mapOptions, styles });
        // commit(LOAD_STYLES, styles);
      } catch (err) {
        handleError(err);
      }
    },
    showPOI: async ({ dispatch, rootState, state }: ActionContext<State, State>) => {
      try {
        // show poi
        rootState?.poiOverlay?.setOpacity(1);
        // show icons
        if (state.showPOI) {
          Object.keys(rootState.poiMarkers).forEach((key: string) => {
            rootState.poiMarkers[key].marker?.setVisible(true);
          });
        }
        const POIRoadsAndLabels: MapTypeStyle[] = state.checkStreets
          ? [
              {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [
                  {
                    visibility: 'simplified',
                  },
                ],
              },
            ]
          : [
              {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'road',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [
                  {
                    visibility: 'simplified',
                  },
                ],
              },
            ];

        // update the map options
        if (rootState.mapOptions && rootState?.map && state.showPOI) {
          const styles: MapTypeStyle[] = poiStyles(rootState.mapOptions?.styles, VISIBILITY.ON);
          rootState.map?.setOptions({
            styles: [...(rootState.mapOptions.styles || []), ...POIRoadsAndLabels],
          });
          // dispatch(reloadMapOptionsAndStyles, styles);
        }
      } catch (err) {
        handleError(err);
      }
    },
    showParking: async ({ rootState, state }: ActionContext<State, State>) => {
      try {
        if (state.checkParking) {
          Object.keys(rootState.parkingMarkers).forEach((key: string) => {
            rootState.parkingMarkers[key].marker?.setVisible(true);
          });
        }
      } catch (err) {
        handleError(err);
      }
    },
    showRoadsAndLabels: async ({ dispatch, rootState, state }: ActionContext<State, State>) => {
      try {
        const POIRoadsAndLabels: MapTypeStyle[] = state.showPOI
          ? [
              {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [
                  {
                    visibility: 'simplified',
                  },
                ],
              },
            ]
          : [];
        if (rootState.mapOptions && rootState?.map && state.checkStreets) {
          const styles: MapTypeStyle[] = roadStyles(rootState.mapOptions, VISIBILITY.ON);
          rootState.map?.setOptions({
            styles: [...(rootState?.mapOptions?.styles || []), ...POIRoadsAndLabels],
          });
          //dispatch(reloadMapOptionsAndStyles, styles);
        }
      } catch (err) {
        handleError(err);
      }
    },
    showSpokies: async ({ rootState, state }: ActionContext<State, State>) => {
      try {
        if (state.checkSpokies) {
          Object.keys(rootState.spokiesMarkers).forEach((key: string) => {
            rootState.spokiesMarkers[key].marker?.setVisible(true);
          });
        }
      } catch (err) {
        handleError(err);
      }
    },
    showTransitCenter: async ({ rootState, state }: ActionContext<State, State>) => {
      try {
        if (state.checkTransitCenter) {
          rootState?.transitCenterMarker?.marker?.setVisible(true);
        }
      } catch (err) {
        handleError(err);
      }
    },
    toggleCheckParking: async (
      { commit, dispatch }: ActionContext<State, State>,
      value: boolean
    ) => {
      try {
        localStorage.setItem(CHECK_PARKING_KEY, value.toString());
        commit(TOGGLE_PARKING, value);
        if (value) {
          dispatch(showParking);
        } else {
          dispatch(hideParking);
        }
      } catch (err) {
        handleError(err);
      }
    },
    toggleCheckSpokies: async (
      { commit, dispatch }: ActionContext<State, State>,
      value: boolean
    ) => {
      try {
        localStorage.setItem(CHECK_SPOKIE_KEY, value.toString());
        commit(TOGGLE_SPOKIES, value);
        if (value) {
          dispatch(showSpokies);
        } else {
          dispatch(hideSpokies);
        }
      } catch (err) {
        handleError(err);
      }
    },
    toggleCheckStreets: async (
      { commit, dispatch }: ActionContext<State, State>,
      value: boolean
    ) => {
      try {
        localStorage.setItem(CHECK_STREET_KEY, value.toString());
        commit(TOGGLE_STREETS, value);
        if (value) {
          dispatch(showRoadsAndLabels);
        } else {
          dispatch(hideRoadsAndLabels);
        }
      } catch (err) {
        handleError(err);
      }
    },
    toggleShowPOI: async ({ commit, dispatch }: ActionContext<State, State>, value: boolean) => {
      try {
        await dispatch(toggleCheckTransitCenter);
        localStorage.setItem(SHOW_POI_KEY, value.toString());
        commit(TOGGLE_POINT_OF_INTEREST, value);
        commit(TOGGLE_TRANSIT_CENTER, value);

        if (value) {
          dispatch(showPOI);
          dispatch(showTransitCenter);
        } else {
          dispatch(hidePOI);
          dispatch(hideTransitCenter);
        }
      } catch (err) {
        handleError(err);
      }
    },
    toggleStopLabel: async ({ commit, rootState }: ActionContext<State, State>, value: boolean) => {
      try {
        localStorage.setItem(STOP_LABEL_KEY, value.toString());
        commit(TOGGLE_STOP_LABELS, value);
        if (value) {
          Object.keys(rootState.stopMarkers).forEach((key: string) => {
            rootState.stopMarkers[key]?.marker?.setLabel({
              text: rootState.stopMarkers[key]?.label?.text || '',
              fontSize,
              fontWeight,
            });
          });
        } else {
          Object.keys(rootState.stopMarkers).forEach((key: string) => {
            rootState.stopMarkers[key]?.marker?.setLabel(null);
          });
        }
      } catch (err) {
        handleError(err);
      }
    },
    toggleCheckTransitCenter: async (
      { commit, dispatch }: ActionContext<State, State>,
      value: boolean
    ) => {
      try {
        // localStorage.setItem(CHECK_TRANSIT_CENTER_KEY, value.toString());
        commit(TOGGLE_TRANSIT_CENTER, value);

        if (value) {
          dispatch(showTransitCenter);
        } else {
          dispatch(hideTransitCenter);
        }
      } catch (err) {
        handleError(err);
      }
    },
  },
  getters: {},
  mutations: {
    TOGGLE_PARKING: (state: State, value: boolean) => {
      state.checkParking = value;
    },
    TOGGLE_POINT_OF_INTEREST: (state: State, value: boolean) => {
      state.showPOI = value;
    },
    TOGGLE_SPOKIES: (state: State, value: boolean) => {
      state.checkSpokies = value;
    },
    TOGGLE_STOP_LABELS: (state: State, value: boolean) => {
      state.showStopLabel = value;
    },
    TOGGLE_STREETS: (state: State, value: boolean) => {
      state.checkStreets = value;
    },
    TOGGLE_TRANSIT_CENTER: (state: State, value: boolean) => {
      state.checkTransitCenter = value;
    },
  },
  state: {
    checkParking: DEFAULT_CHECK_PARKING,
    checkSpokies: DEFAULT_CHECK_PARKING,
    checkTransitCenter: DEFAULT_CHECK_TRANSIT_CENTER,
    checkStreets: DEFAULT_CHECK_STREET,
    showPOI: DEFAULT_SHOW_POI,
    showStopLabel: DEFAULT_SHOW_STOP_LABEL,
  },
};
