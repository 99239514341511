<template>
  <g-marker
    v-if="streetCarMarker"
    :clickable="true"
    :content="streetCarMarker.content"
    :draggable="false"
    :esn="streetCarMarker.esn"
    :icon="streetCarMarker.icon"
    :opened="streetCarMarker.opened"
    :position="streetCarMarker.position"
    :title="streetCarMarker.title"
    :type="streetCarMarker.type"
    :zIndex="101"
  >
    <template v-slot="{ marker, map }">
      <info-window v-if="streetCarMarker.content" :map="map" :marker="marker">
        <div class="marker-window streetcar" v-if="marker?.opened && marker.type === type">
          <div>
            <div
              v-bind:class="[streetCarMarker.content.nonRevenue, 'streetcar-number']"
              v-if="streetCarMarker.content.number"
            >
              Streetcar #{{ streetCarMarker.content.number }}
            </div>
            <div
              v-bind:class="[streetCarMarker.content.routeClass, 'streetcar-route']"
              v-if="streetCarMarker.content.route"
            >
              {{ streetCarMarker.content.route }}
            </div>
          </div>
        </div>
      </info-window></template
    >
  </g-marker>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import InfoWindow from '@/components/InfoWindow.vue';
import GMarker from '@/components/GMarker.vue';
import { StreetCarMarker } from '@/types';
export default defineComponent({
  name: 'Street Car Marker',
  components: { GMarker, InfoWindow },
  props: {
    streetcar: Object as PropType<StreetCarMarker>,
  },
  setup(props) {
    const streetCarMarker = ref(props.streetcar);
    const type = ref(props.streetcar?.type);
    return { streetCarMarker, type };
  },
});
</script>

<style scoped lang="scss"></style>
