<template>
  <div class="google-map" @click="closeAllMarkers">
    <map-loader :center="center" :mapTypeId="mapTypeId" :zoom="zoom">
      <poi-overlay v-if="getScreenType === deviceScreenType" />
      <device-streetcar-track-overlay v-if="getScreenType === deviceScreenType" />
      <kiosk-streetcar-track-overlay v-if="getScreenType === kioskScreenType" />
      <user-location
        v-if="getScreenType === deviceScreenType && getUserLocation"
        ref="userLocationRef"
        :icon="getUserIcon"
        :position="getUserLocation"
        :zIndex="1000"
      />
      <template ref="streetCarMarkersRef" v-for="esn in getInserviceESN" :key="esn">
        <street-car-marker
          v-if="getStreetCars[esn]"
          :streetcar="getStreetCars[esn]"
        ></street-car-marker>
      </template>
      <template v-if="getScreenType === deviceScreenType">
        <parking-marker
          ref="parkingMarkersRef"
          v-for="parking in getParkings"
          :key="parking.esn"
          :parking="parking"
        />
      </template>
      <poi-marker ref="poiMarkersRef" v-for="poi in getPois" :key="poi.esn" :poi="poi" />
      <template v-if="getScreenType === deviceScreenType">
        <spokie-marker
          ref="spokieMarkersRef"
          v-for="spokie in getSpokies"
          :key="spokie.esn"
          :spokie="spokie"
        />
      </template>
      <transit-center-marker
        ref="transitCenterMarkerRef"
        v-if="transitCenterMarker && getScreenType === deviceScreenType"
        :key="transitCenterMarker.esn"
        :transitCenter="transitCenterMarker"
      />
      <template v-if="getScreenType === deviceScreenType">
        <stop-marker ref="stopMarkersRef" v-for="stop in stops" :key="stop.esn" :stop="stop" />
      </template>
    </map-loader>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import DeviceStreetcarTrackOverlay from '@/components/DeviceStreetcarTrackOverlay.vue';
import KioskStreetcarTrackOverlay from '@/components/KioskStreetcarTrackOverlay.vue';
import MapLoader from '@/components/MapLoader.vue';
import ParkingMarker from '@/components/ParkingMarker.vue';
import PoiMarker from '@/components/PoiMarker.vue';
import PoiOverlay from '@/components/PoiOverlay.vue';
import SpokieMarker from '@/components/SpokieMarker.vue';
import StopMarker from '@/components/StopMarker.vue';
import StreetCarMarker from '@/components/StreetCarMarker.vue';
import TransitCenterMarker from '@/components/TransitCenterMarker.vue';
import UserLocation from '@/components/UserLocation.vue';
import {
  ASSET_CHECK_INTERVAL,
  checkCannedMessages,
  clearWindowInfo,
  getAssets,
  MESSAGE_CHECK_INTERVAL,
  subscribeToUsfleetDataStream,
  subscribeToUsfleetStopsDataStream,
  unSubscribeToUsfleetDataStream,
  unSubscribeToUsfleetStopsDataStream,
} from '@/utils';
import { SCREEN_TYPE } from '@/enums';
export default defineComponent({
  name: 'Google Map',
  components: {
    DeviceStreetcarTrackOverlay,
    KioskStreetcarTrackOverlay,
    MapLoader,
    ParkingMarker,
    PoiMarker,
    PoiOverlay,
    SpokieMarker,
    StopMarker,
    StreetCarMarker,
    TransitCenterMarker,
    UserLocation,
  },
  setup() {
    let alertMessageId: number;
    let assetDataId: number;

    // reference
    const parkingMarkersRef = ref();
    const poiMarkersRef = ref();
    const stopMarkersRef = ref();
    const transitCenterMarkerRef = ref();
    const userLocationRef = ref();
    const deviceScreenType = ref(SCREEN_TYPE.DEVICE);
    const kioskScreenType = ref(SCREEN_TYPE.KIOSK);
    // state properties
    const store = useStore();
    const center = computed(() => store.state.center);
    // const mapTypeStyles = computed(() => store.state.mapTypeStyles);
    const mapTypeId = computed(() => store.state.mapTypeId);
    // const mapOptions = computed(() => store.state.mapOptions);
    const openWindowMarker = computed(() => store.state.openWindowMarker);
    const streetCarMarkers = computed(() => store.state.streetCarMarkers);
    const stops = computed(() => store.state.stops);
    const transitCenterMarker = computed(() => store.state.transitCenterMarker);
    const usFleetSubscription = computed(() => store.state.usFleetSubscription);
    const zoom = computed(() => store.state.zoom);
    // getters
    const getInserviceESN = computed(() => store.getters.getInserviceESN);
    const getParkings = computed(() => store.getters.getParkings);
    const getPois = computed(() => store.getters.getPois);
    const getScreenType = computed(() => store.getters.getScreenType);
    const getSpokies = computed(() => store.getters.getSpokies);
    const getStreetCars = computed(() => store.getters.getStreetCars);
    const getTransitCenter = computed(() => store.getters.getTransitCenter);
    const getUserIcon = computed(() => store.getters.getUserIcon);
    const getUserLocation = computed(() => store.getters.getUserLocation);

    // methods
    const checkMessages = () => {
      store.dispatch(checkCannedMessages);
      alertMessageId = setInterval(
        () => store.dispatch(checkCannedMessages),
        MESSAGE_CHECK_INTERVAL
      );
    };

    // eslint-disable-next-line
    const closeAllMarkers = (event: any) => {
      if (event?.target?.tagName?.toLowerCase() === 'div') {
        store.state?.openWindowMarker?.close();
        store.dispatch(clearWindowInfo);
      }
    };
    const loadAssets = async () => {
      await store.dispatch(getAssets);
      assetDataId = setInterval(() => store.dispatch(getAssets), ASSET_CHECK_INTERVAL);
    };
    const subscribe = async () => {
      // subscribe to data from usfleet using vuex
      await store.dispatch(subscribeToUsfleetDataStream);
      await store.dispatch(subscribeToUsfleetStopsDataStream);
      checkMessages();
    };
    onMounted(async () => {
      await subscribe();
      await loadAssets();
    });
    const unSubscribe = async () => {
      // Make sure to close the connection with the events server
      // when the component is destroyed, or we'll have ghost connections!
      await store.dispatch(unSubscribeToUsfleetDataStream);
      await store.dispatch(unSubscribeToUsfleetStopsDataStream);
      // stop checking for alert Messages
      clearInterval(alertMessageId);
      clearInterval(assetDataId);
    };
    onUnmounted(() => {
      unSubscribe();
    });

    return {
      center,
      closeAllMarkers,
      deviceScreenType,
      getInserviceESN,
      getParkings,
      getPois,
      getScreenType,
      getSpokies,
      getStreetCars,
      getTransitCenter,
      getUserIcon,
      getUserLocation,
      kioskScreenType,
      mapTypeId,
      openWindowMarker,
      parkingMarkersRef,
      poiMarkersRef,
      stopMarkersRef,
      stops,
      streetCarMarkers,
      transitCenterMarker,
      transitCenterMarkerRef,
      usFleetSubscription,
      userLocationRef,
      zoom,
    };
  },
});
</script>

<style scoped lang="scss"></style>
