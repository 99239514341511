
import { computed, defineComponent, PropType, ref } from 'vue';
import InfoWindow from '@/components/InfoWindow.vue';
import GMarker from '@/components/GMarker.vue';
import { StreetCarMarker } from '@/types';
export default defineComponent({
  name: 'Street Car Marker',
  components: { GMarker, InfoWindow },
  props: {
    streetcar: Object as PropType<StreetCarMarker>,
  },
  setup(props) {
    const streetCarMarker = ref(props.streetcar);
    const type = ref(props.streetcar?.type);
    return { streetCarMarker, type };
  },
});
