import { SCREEN_TYPE } from '@/enums';
import {
  AssetData,
  Duration,
  Icon,
  IConOption,
  Icons,
  LatLng,
  MapOptions,
  MapTypeStyle,
  Message,
  MessageSwitch,
  ParkingMarker,
  POIMarker,
  SpokieMarker,
  State,
  StreetCarDeviceESN,
  StopMarker,
  StreetCar,
  StreetCarMarker,
  Subscription,
  TransitCenterMarker,
} from '@/types';

export const mutations = {
  CLEAR_ALERT_MESSAGE: (state: State) => {
    state.alertMessage = {};
  },
  INSERT_PARKING_MARKERS: (state: State, parkings: ParkingMarker[]) => {
    state.parkings = parkings;
  },
  INSERT_POI_MARKERS: (state: State, pois: POIMarker[]) => {
    state.pois = pois;
  },
  INSERT_SPOKIES_MARKERS: (state: State, spokies: SpokieMarker[]) => {
    state.spokies = spokies;
  },
  INSERT_STREETCAR_MARKER: async (state: State, street_car_marker: StreetCarMarker) => {
    if (street_car_marker?.esn) {
      state.streetCarMarkers[street_car_marker.esn] = street_car_marker;
    }
  },
  INSERT_TRANSIT_CENTER_MARKER: (state: State, transitCenterMarker: TransitCenterMarker) => {
    state.transitCenterMarker = transitCenterMarker;
  },
  LOAD_ALERT_MESSAGE: (state: State, alertMessage: string) => {
    if (state?.alertMessage) {
      state.alertMessage = { ...state.alertMessage, usFleetAlertMessage: alertMessage };
    }
  },

  LOAD_BRICKTOWN_ALERT_MESSAGE: (state: State, alertMessage: string) => {
    if (state?.alertMessage) {
      state.alertMessage = { ...state.alertMessage, bricktownAlertMessage: alertMessage };
    }
  },
  LOAD_CUSTOM_DISPLAY_MESSAGES: (
    state: State,
    { customBricktownMessage, customDowntownMessage, status }: MessageSwitch
  ) => {
    state.customBricktownMessage = customBricktownMessage;
    state.customDowntownMessage = customDowntownMessage;
    state.customMessage = status;
  },
  LOAD_DEVICE_SCREEN: (state: State, screen: SCREEN_TYPE) => {
    state.screen_type = screen;
  },
  LOAD_DOWNTOWN_ALERT_MESSAGE: (state: State, alertMessage: string) => {
    if (state?.alertMessage) {
      state.alertMessage = { ...state.alertMessage, downtownAlertMessage: alertMessage };
    }
  },
  LOAD_GOOGLE: (state: State, google: google.maps.Map) => {
    state.google = google;
  },
  LOAD_ICONS: (state: State, icons: Icons) => {
    state.icons = icons;
  },
  LOAD_INSERVICE_STREET_CAR_ESN: (state: State, esns: number[]) => {
    state.inServiceStreetCarESN = esns;
  },
  LOAD_MAP: (state: State, map: google.maps.Map) => {
    state.map = map;
  },
  LOAD_MAP_OPTIONS: (state: State, mapOptions: MapOptions) => {
    state.mapOptions = mapOptions;
  },
  LOAD_STOP_MARKERS: (state: State, stops: StopMarker[]) => {
    state.stops = stops;
  },
  LOAD_STOP_MESSAGES: (state: State, messages: Message) => {
    state?.stops?.forEach(stop => {
      if (stop?.content) {
        stop.content.alertMessage = messages[stop.id];
      }
    });
  },
  LOAD_STOP_SPONSORS_AND_STOP_GOOGLE_LINKS: (state: State, sponsorsAndGoogleLink = null) => {
    state.sponsorsAndGoogleLink = sponsorsAndGoogleLink;
  },
  LOAD_STREET_CARS: (state: State, streetcars: StreetCar[]) => {
    state.streetcars = streetcars;
  },
  LOAD_STREET_CAR_DEVICE_ESN: (state: State, streetcarDeviceESN: StreetCarDeviceESN) => {
    state.streetcarDeviceESN = streetcarDeviceESN;
  },
  LOAD_STREET_CAR_ICON_OPTIONS: (
    state: State,
    { blue_options, green_options, grey_options, pink_options }: IConOption
  ) => {
    state.streetcar_icon_options = {
      blue_options,
      green_options,
      grey_options,
      pink_options,
    };
  },
  LOAD_STYLES: (state: State, styles: MapTypeStyle[]) => {
    state.mapTypeStyles = styles;
  },
  LOAD_USER_LOCATION_ICONS: (state: State, icon: string) => {
    state.userIcon = icon;
  },
  OPEN_MARKER_WINDOW: (state: State, window: google.maps.InfoWindow) => {
    state.openWindowMarker = window;
  },
  OPEN_MARKER_WINDOW_KEY: (state: State, key: string) => {
    state.openWindowMarkerKey = key;
  },
  REMOVE_STREET_CAR_MARKER: (state: State, street_car_marker: StreetCarMarker) => {
    // bug in vue, doesn't remove the icon
    street_car_marker?.marker?.setMap(null); // this is supposed to work
  },
  SUBSCRIBE_TO_USFLEET_DATA_STREAM: (state: State, connection: Subscription) => {
    state.usFleetSubscription = connection;
  },
  SUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM: (state: State, stop_connection: Subscription) => {
    state.usFleetStopsSubscription = stop_connection;
  },
  TOGGLE_MESSAGE: (state: State, value: boolean) => {
    state.alertMessageOpened = value;
  },
  TOGGLE_MOBILE_LEGEND: (state: State, value: boolean) => {
    state.openMobileLegend = value;
  },

  TURN_ON_STOPS_DISPLAY_ARRIVAL: (state: State) => {
    state.stops?.forEach(stop => {
      if (stop.content) {
        stop.content.displayArrival = true;
      }
    });
  },
  TURN_OFF_STOPS_DISPLAY_ARRIVAL: (state: State) => {
    state.stops?.forEach(stop => {
      if (stop.content) {
        stop.content.displayArrival = false;
      }
    });
  },
  UNSUBSCRIBE_TO_USFLEET_DATA_STREAM: (state: State) => {
    state.usFleetSubscription = undefined;
  },
  UNSUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM: (state: State) => {
    state.usFleetStopsSubscription = undefined;
  },
  UPDATE_ASSET_DATA: (state: State, assets: AssetData[]) => {
    state.assetData = assets;
  },
  UPDATE_BRICKTOWN_STOP_DURATION: (state: State, bricktownStopDuration: Duration) => {
    if (bricktownStopDuration?.stopId) {
      state.bricktownDurations[bricktownStopDuration?.stopId] = bricktownStopDuration;
    }
  },
  UPDATE_DOWNTOWN_STOP_DURATION: (state: State, downtownStopDuration: Duration) => {
    if (downtownStopDuration?.stopId) {
      state.downtownDurations[downtownStopDuration?.stopId] = downtownStopDuration;
    }
  },
  UPDATE_PARKING_MARKERS: (state: State, parkingMarker: ParkingMarker) => {
    if (parkingMarker.esn) {
      state.parkingMarkers[parkingMarker.esn] = parkingMarker;
    }
  },
  UPDATE_POI_MARKER: (state: State, poiMarker: POIMarker) => {
    if (poiMarker.esn) {
      state.poiMarkers[poiMarker.esn] = poiMarker;
    }
  },
  UPDATE_POI_OVERLAY: (state: State, poiOverlay: google.maps.GroundOverlay) => {
    state.poiOverlay = poiOverlay;
  },
  UPDATE_SPOKIES_MARKERS: (state: State, spokieMarker: SpokieMarker) => {
    if (spokieMarker.esn) {
      state.spokiesMarkers[spokieMarker.esn] = spokieMarker;
    }
  },
  UPDATE_TRANSIT_CENTER_MARKER: (state: State, transitCenterMarker: TransitCenterMarker) => {
    if (transitCenterMarker) {
      state.transitCenterMarker = transitCenterMarker;
    }
  },
  UPDATE_SET_TIME: (state: State, setTime: number) => {
    state.setTime = setTime;
  },
  UPDATE_STOP_MARKER: (state: State, stopMarker: StopMarker) => {
    if (stopMarker.esn) {
      state.stopMarkers[stopMarker.esn] = stopMarker;
    }
  },
  UPDATE_STOP_MESSAGE: (state: State, { stopId, message }: { stopId: number; message: string }) => {
    const foundStop = state.stops?.find((stop: StopMarker) => stop.id == stopId);
    if (foundStop?.content) {
      foundStop.content.alertMessage = message;
    }
  },

  UPDATE_STOPS_MESSAGE: (state: State, message: string) => {
    state?.stops?.forEach((stop: StopMarker) => {
      if (stop?.content) {
        stop.content.alertMessage = message;
      }
    });
  },
  UPDATE_STREET_CAR_ICON_AND_POSITION: async (
    state: State,
    { esn, icon, position: { lat, lng } }: { esn: number; icon: Icon; position: LatLng }
  ) => {
    const foundMarker = state.streetCarMarkers[esn];
    if (foundMarker) {
      const google = state.google;
      if (google) {
        const newLatLng = new google.maps.LatLng(lat, lng);
        foundMarker.marker?.setPosition(newLatLng);
      }

      foundMarker.marker?.set('icon', icon);
    }
  },
  UPDATE_STREET_CAR_MARKER: async (
    state: State,
    { esn, marker }: { esn: number; marker: google.maps.Marker }
  ) => {
    if (state.streetCarMarkers[esn]) {
      state.streetCarMarkers[esn].marker = marker;
    }
  },
  UPDATE_TOKEN: (state: State, token: string) => {
    state.token = token;
  },
  UPDATE_USER_LOCATION: (state: State, position: LatLng) => {
    state.userLocation = position;
  },
};
