export enum POI_IDS {
  MYRIAD_BOTANICAL = 1,
  SCISSORTAIL_PARK = 2,
  KERR_PARK = 3,
  BICENTENNIAL_PARK = 4,
  BRICKTOWN_CANAL = 5,
  CAMPELL_ART_PARK = 6,
  MIDTOWN_MUTTS = 7,
  TOGETHER_SQUARE = 8,
  NATIONAL_MEMORIAL = 9,
  RED_EARTH = 10,
  AMERICAN_BANJO_MUSEUM = 11,
  FACTORY_OBSCURA = 12,
  SPORTS_HALL_OF_FAME = 13,
  OKLAHOMA_CONTEMPORARY_ARTS = 14,
  MUSEUM_OF_ART = 15,
  CITY_HALL = 16,
  DOWNTOWN_LIBRARY = 17,
  CHESAPEAKE_ARENA = 18,
  BRICKTOWN_BALLPARK = 19,
  COX_CONVENTION_CENTER = 20,
  THE_CRITERION = 21,
  CIVIC_CENTER = 22,
  SANTA_FE_DEPOT = 23,
  BRICKTOWN_WATER_TAXI = 24,
}
