import { ParkingMarker } from '@/types';

export const parking: ParkingMarker[] = [
  {
    name: 'Sheridan Walker',
    address: '501 W Sheridan Ave',
    position: { lat: 35.46701406035363, lng: -97.52174019813538 },
    googleLink: 'https://goo.gl/maps/VxZQeHtpCVkPgBubA',
    iconAnchor: { x: 9, y: 9 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    name: 'Arts District',
    address: '431 W Main St',
    position: { lat: 35.468150004983976, lng: -97.52099990844727 },
    googleLink: 'https://goo.gl/maps/ZnnL3nXrWiHR3kceA',
    iconAnchor: { x: 0, y: 9 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    name: 'Century Center',
    address: '100 W Main St',
    position: { lat: 35.46803641124298, lng: -97.51507759094238 },
    googleLink: 'https://goo.gl/maps/AgqjBbmDXh69aEd88',
    iconAnchor: { x: 9, y: 5 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    name: 'Cox Convention',
    address: '1 Myriad Gardens',
    position: { lat: 35.46528389847233, lng: -97.51469135284424 },
    googleLink: 'https://goo.gl/maps/1PFJqtxdC4S3qEjN7',
    iconAnchor: { x: 25, y: 9 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    name: 'Surface Lot',
    address: 'EK Gaylord and Reno',
    position: { lat: 35.4641482, lng: -97.5129246 },
    googleLink: 'https://goo.gl/maps/r7R1WQrRgF3CEd7Q6',
    iconAnchor: { x: 0, y: -20 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    name: 'Santa Fe East',
    address: 'EK Gaylord and Main',
    position: { lat: 35.4670188, lng: -97.5134752 },
    googleLink: 'https://goo.gl/maps/AkUVBu3NcycuYJSa7',
    iconAnchor: { x: 25, y: 9 },
    iconOrigin: { x: 0, y: 0 },
  },
  {
    name: 'Santa Fe West',
    address: 'EK Gaylord and Sheridan',
    position: { lat: 35.4656899, lng: -97.5133458 },
    googleLink: 'https://goo.gl/maps/HUoC3xETRib8P9n37',
    iconAnchor: { x: 0, y: -20 },
    iconOrigin: { x: 0, y: 0 },
  },
];
