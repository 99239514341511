import { StopMarker } from '@/types';

export const stops: StopMarker[] = [
  {
    id: 55,
    number: 0,
    name: 'USFT',
    routeIds: [8, 9],
    latitude: 35.62969,
    longitude: -97.56753,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 56,
    number: 0,
    name: 'NW 150th @ May',
    routeIds: [8],
    latitude: 35.62395,
    longitude: -97.56724,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 57,
    number: 0,
    name: 'NW 150th @ Portland',
    routeIds: [8],
    latitude: 35.62361,
    longitude: -97.58501,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 58,
    number: 0,
    name: 'NW 164th @ Portland',
    routeIds: [8],
    latitude: 35.63845,
    longitude: -97.5831,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 59,
    number: 0,
    name: 'NW 164th @ May',
    routeIds: [8],
    latitude: 35.63844,
    longitude: -97.56738,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 1,
    number: 1,
    name: '#1 East Bricktown',
    routeIds: [1, 5],
    latitude: 35.4655741,
    longitude: -97.5061144,
    scale: 5,
    strokeColor: '92187B',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 2,
    number: 2,
    name: '#2 Ballpark',
    routeIds: [1, 5],
    latitude: 35.4643283,
    longitude: -97.5088322,
    scale: 5,
    strokeColor: '92187B',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 3,
    number: 3,
    name: '#3 Santa Fe Hub',
    routeIds: [1, 5],
    latitude: 35.4643263,
    longitude: -97.5120226,
    scale: 5,
    strokeColor: '92187B',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 4,
    number: 4,
    name: '#4 Arena',
    routeIds: [1, 5],
    latitude: 35.4643592,
    longitude: -97.5150977,
    scale: 5,
    strokeColor: '92187B',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 5,
    number: 5,
    name: '#5 Scissortail Park',
    routeIds: [1, 5],
    latitude: 35.4624277,
    longitude: -97.5167542,
    scale: 5,
    strokeColor: '92187B',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 6,
    number: 6,
    name: '#6 Myriad Gardens',
    routeIds: [1, 5],
    latitude: 35.4661055,
    longitude: -97.5194301,
    scale: 5,
    strokeColor: '92187B',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 7,
    number: 7,
    name: '#7 Library',
    routeIds: [1],
    latitude: 35.4686281,
    longitude: -97.519406,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 8,
    number: 8,
    name: '#8 Transit Center',
    routeIds: [1],
    latitude: 35.4716439,
    longitude: -97.5193264,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 9,
    number: 9,
    name: '#9 Federal Courthouse',
    routeIds: [1, 7],
    latitude: 35.4718639,
    longitude: -97.5159596,
    scale: 5,
    strokeColor: '745267',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 10,
    number: 10,
    name: '#10 Broadway Avenue',
    routeIds: [1],
    latitude: 35.4724438,
    longitude: -97.5142113,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 11,
    number: 11,
    name: '#11 Automobile Alley',
    routeIds: [1],
    latitude: 35.4758325,
    longitude: -97.514095,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 12,
    number: 12,
    name: '#12 Art Park',
    routeIds: [1],
    latitude: 35.4799131,
    longitude: -97.5139082,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 13,
    number: 13,
    name: '#13 North Hudson',
    routeIds: [1],
    latitude: 35.4800569,
    longitude: -97.5188978,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 14,
    number: 14,
    name: '#14 Dewey Avenue',
    routeIds: [1],
    latitude: 35.4795025,
    longitude: -97.5227273,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 15,
    number: 15,
    name: '#15 Midtown',
    routeIds: [1],
    latitude: 35.4781767,
    longitude: -97.5194328,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 16,
    number: 16,
    name: '#16 NW 10th Street',
    routeIds: [1],
    latitude: 35.4781054,
    longitude: -97.5165203,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 17,
    number: 17,
    name: '#17 Law School',
    routeIds: [1],
    latitude: 35.4752981,
    longitude: -97.5162552,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 18,
    number: 18,
    name: '#18 Memorial Museum',
    routeIds: [1],
    latitude: 35.4721471,
    longitude: -97.5163601,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 19,
    number: 19,
    name: '#19 Business District',
    routeIds: [1],
    latitude: 35.4690103,
    longitude: -97.5164853,
    scale: 5,
    strokeColor: 'E70F47',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 20,
    number: 20,
    name: '#20 Century Center',
    routeIds: [1, 5],
    latitude: 35.4663597,
    longitude: -97.5154582,
    scale: 5,
    strokeColor: '92187B',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 21,
    number: 21,
    name: '#21 Bricktown',
    routeIds: [1, 5, 7],
    latitude: 35.4663595,
    longitude: -97.5116775,
    scale: 5,
    strokeColor: '495681',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 22,
    number: 22,
    name: '#22 Mickey Mantle',
    routeIds: [1, 5],
    latitude: 35.4663756,
    longitude: -97.5094618,
    scale: 5,
    strokeColor: '92187B',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 199,
    number: 199,
    name: 'Staplegun Test Stop',
    routeIds: [10],
    latitude: 35.63844,
    longitude: -97.56738,
    scale: 5,
    strokeColor: 'D51067',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 24,
    number: 215,
    name: '#215 Bass Pro Shops',
    routeIds: [7],
    latitude: 35.46394,
    longitude: -97.50353,
    scale: 5,
    strokeColor: 'D51067',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 53,
    number: 335,
    name: '#335 Broadway @ Dean McGee',
    routeIds: [7],
    latitude: 35.46864,
    longitude: -97.51451,
    scale: 5,
    strokeColor: '009487',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 49,
    number: 1605,
    name: '#1605 NE 6th @ NW 6th',
    routeIds: [7],
    latitude: 35.473889,
    longitude: -97.510626,
    scale: 5,
    strokeColor: '009487',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 51,
    number: 1812,
    name: '#1812 NW 4th @ Harvey',
    routeIds: [7],
    latitude: 35.471938,
    longitude: -97.517718,
    scale: 5,
    strokeColor: '009487',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 38,
    number: 3003,
    name: '#3003 Broadway @ Park Ave',
    routeIds: [7],
    latitude: 35.46864,
    longitude: -97.51451,
    scale: 5,
    strokeColor: '009487',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 42,
    number: 3004,
    name: '#3004 Reno Ave @ Oklahoma Ave',
    routeIds: [7],
    latitude: 35.46423,
    longitude: -97.51049,
    scale: 5,
    strokeColor: '009487',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 43,
    number: 3005,
    name: '#3005 Reno @ Mickey Mantle Dr',
    routeIds: [7],
    latitude: 35.46423,
    longitude: -97.50895,
    scale: 5,
    strokeColor: '009487',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 44,
    number: 4020,
    name: '#4020 Bricktown Landing',
    routeIds: [7],
    latitude: 35.4615,
    longitude: -97.5046,
    scale: 5,
    strokeColor: '009487',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 33,
    number: 4050,
    name: '#4050 Transit Center Bay R',
    routeIds: [7],
    latitude: 35.47242,
    longitude: -97.51939,
    scale: 5,
    strokeColor: '009487',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 50,
    number: 4064,
    name: '#4064 NW 6th @ Harvey',
    routeIds: [7],
    latitude: 35.474037,
    longitude: -97.516521,
    scale: 5,
    strokeColor: '009487',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 48,
    number: 4066,
    name: '#4066 Oklahoma Ave @ NE 4th',
    routeIds: [7],
    latitude: 35.47202,
    longitude: -97.510443,
    scale: 5,
    strokeColor: '009487',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 47,
    number: 4067,
    name: '#4067 NE 2nd @ Walnut',
    routeIds: [7],
    latitude: 35.469655,
    longitude: -97.509259,
    scale: 5,
    strokeColor: '009487',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 46,
    number: 4068,
    name: '#4068 Russel Perry @ NE 1st',
    routeIds: [7],
    latitude: 35.466438,
    longitude: -97.503809,
    scale: 5,
    strokeColor: '009487',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
  {
    id: 45,
    number: 4069,
    name: '#4069 Sheridan @ Charlie Christian',
    routeIds: [7],
    latitude: 35.46394,
    longitude: -97.50353,
    scale: 5,
    strokeColor: '009487',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: 'FFFFFF',
    fillOpacity: 1,
  },
];
