<template>
  <g-marker
    v-if="position"
    :clickable="false"
    :icon="userIcon"
    :position="userPosition"
    :type="'user'"
    :zIndex="1000"
  >
  </g-marker>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import GMarker from '@/components/GMarker.vue';
import { Icon, LatLng, ParkingMarker } from '@/types';
export default defineComponent({
  name: 'User Location',
  components: { GMarker },
  props: {
    icon: Object as PropType<Icon>,
    position: Object as PropType<LatLng>,
    zIndex: Number,
  },
  setup(props) {
    const index = ref(props.zIndex);
    const userIcon = ref(props.icon);
    const userPosition = ref(props.position);
    return { index, userIcon, userPosition };
  },
});
</script>

<style scoped lang="scss"></style>
