export enum MARKER_TYPE {
  ATTRACTION = 'attraction',
  CITY = 'city',
  MUSEUM = 'museum',
  PARKING = 'parking',
  SANTA_FE_DPOT = 'santa_fe_depot',
  SPOKIE = 'spokie',
  STOP = 'stop',
  STREET_CAR = 'streetcar',
  TRANSIT_CENTER = 'transit_center',
  USER = 'user',
  WATER_TAXI = 'water_taxi',
}
