export const CLEAR_ALERT_MESSAGE = 'CLEAR_ALERT_MESSAGE';
export const INSERT_PARKING_MARKERS = 'INSERT_PARKING_MARKERS';
export const INSERT_SPOKIES_MARKERS = 'INSERT_SPOKIES_MARKERS';
export const INSERT_STREETCAR_MARKER = 'INSERT_STREETCAR_MARKER';
export const INSERT_TRANSIT_CENTER_MARKER = 'INSERT_TRANSIT_CENTER_MARKER';
export const LOAD_ALERT_MESSAGE = 'LOAD_ALERT_MESSAGE';
export const LOAD_BRICKTOWN_ALERT_MESSAGE = 'LOAD_BRICKTOWN_ALERT_MESSAGE';
export const LOAD_CUSTOM_DISPLAY_MESSAGES = 'LOAD_CUSTOM_DISPLAY_MESSAGES';
export const LOAD_DEVICE_SCREEN = 'LOAD_DEVICE_SCREEN';
export const LOAD_DOWNTOWN_ALERT_MESSAGE = 'LOAD_DOWNTOWN_ALERT_MESSAGE';
export const LOAD_FRESH_DEVICES = 'LOAD_FRESH_DEVICES';
export const LOAD_GOOGLE = 'LOAD_GOOGLE';
export const LOAD_ICONS = 'LOAD_ICONS';
export const LOAD_INSERVICE_STREET_CAR_ESN = 'LOAD_INSERVICE_STREET_CAR_ESN';
export const LOAD_MAP = 'LOAD_MAP';
export const LOAD_MAP_OPTIONS = 'LOAD_MAP_OPTIONS';
export const LOAD_STOP_MARKERS = 'LOAD_STOP_MARKERS';
export const LOAD_STOP_MESSAGES = 'LOAD_STOP_MESSAGES';
export const LOAD_STOP_SPONSORS_AND_STOP_GOOGLE_LINKS = 'LOAD_STOP_SPONSORS_AND_STOP_GOOGLE_LINKS';
export const LOAD_STREET_CARS = 'LOAD_STREET_CARS';
export const LOAD_STREET_CAR_ICON_OPTIONS = 'LOAD_STREET_CAR_ICON_OPTIONS';
export const LOAD_STREET_CAR_DEVICE_ESN = 'LOAD_STREET_CAR_DEVICE_ESN';
// export const LOAD_STREET_CAR_DEVICE_ESNS = 'LOAD_STREET_CAR_DEVICE_ESNS';
export const LOAD_STYLES = 'LOAD_STYLES';
export const LOAD_USER_LOCATION_ICONS = 'LOAD_USER_LOCATION_ICONS';
export const OPEN_MARKER_WINDOW = 'OPEN_MARKER_WINDOW';
export const OPEN_MARKER_WINDOW_KEY = 'OPEN_MARKER_WINDOW_KEY';
export const INSERT_POI_MARKERS = 'INSERT_POI_MARKERS';
export const REMOVE_STREET_CAR_MARKER = 'REMOVE_STREET_CAR_MARKER';
export const SUBSCRIBE_TO_USFLEET_DATA_STREAM = 'SUBSCRIBE_TO_USFLEET_DATA_STREAM';
export const SUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM = 'SUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM';
export const TOGGLE_MESSAGE = 'TOGGLE_MESSAGE';
export const TOGGLE_MOBILE_LEGEND = 'TOGGLE_MOBILE_LEGEND';
export const TOGGLE_PARKING = 'TOGGLE_PARKING';
export const TOGGLE_POINT_OF_INTEREST = 'TOGGLE_POINT_OF_INTEREST';
export const TOGGLE_SPOKIES = 'TOGGLE_SPOKIES';
export const TOGGLE_STOP_LABELS = 'TOGGLE_STOP_LABELS';
export const TOGGLE_STREETS = 'TOGGLE_STREETS';
export const TOGGLE_TRANSIT_CENTER = 'TOGGLE_TRANSIT_CENTER';
export const TURN_ON_STOPS_DISPLAY_ARRIVAL = 'TURN_ON_STOPS_DISPLAY_ARRIVAL';
export const TURN_OFF_STOPS_DISPLAY_ARRIVAL = 'TURN_OFF_STOPS_DISPLAY_ARRIVAL';
export const UNSUBSCRIBE_TO_USFLEET_DATA_STREAM = 'UNSUBSCRIBE_TO_USFLEET_DATA_STREAM';
export const UNSUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM = 'UNSUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM';
export const UPDATE_ASSET_DATA = 'UPDATE_ASSET_DATA';
export const UPDATE_BRICKTOWN_STOP_DURATION = 'UPDATE_BRICKTOWN_STOP_DURATION';
export const UPDATE_DOWNTOWN_STOP_DURATION = 'UPDATE_DOWNTOWN_STOP_DURATION';
export const UPDATE_PARKING_MARKERS = 'UPDATE_PARKING_MARKERS';
export const UPDATE_POI_MARKER = 'UPDATE_POI_MARKER';
export const UPDATE_POI_OVERLAY = 'UPDATE_POI_OVERLAY';
export const UPDATE_SPOKIES_MARKERS = 'UPDATE_SPOKIES_MARKERS';
export const UPDATE_SET_TIME = 'UPDATE_SET_TIME';
export const UPDATE_STOP_MARKER = 'UPDATE_STOP_MARKER';
export const UPDATE_STOP_MESSAGE = 'UPDATE_STOP_MESSAGE';
export const UPDATE_STOPS_MESSAGE = 'UPDATE_STOPS_MESSAGE';
export const UPDATE_STREET_CAR_ICON_AND_POSITION = 'UPDATE_STREET_CAR_ICON_AND_POSITION';
export const UPDATE_STREET_CAR_MARKER = 'UPDATE_STREET_CAR_MARKER';
export const UPDATE_TRANSIT_CENTER_MARKER = 'UPDATE_TRANSIT_CENTER_MARKER';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPDATE_USER_LOCATION = 'UPDATE_USER_LOCATION';
