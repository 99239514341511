<template>
  <div class="map-controls-dislaimer-ribbon hide-mobile">
    <img :src="disclaimer_ribbon" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import ribbon from '../assets/icons/map-controls/disclaimer-ribbon.svg';
export default defineComponent({
  name: 'Disclaimer Banner',
  setup(props) {
    const disclaimer_ribbon = ref(ribbon);
    return { disclaimer_ribbon };
  },
});
</script>

<style scoped lang="scss"></style>
