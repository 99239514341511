
import { computed, defineComponent, PropType, ref } from 'vue';
import InfoWindow from '@/components/InfoWindow.vue';
import GMarker from '@/components/GMarker.vue';
import { ParkingMarker } from '@/types';
export default defineComponent({
  name: 'Parking Marker',
  components: { GMarker, InfoWindow },
  props: {
    parking: Object as PropType<ParkingMarker>,
  },
  setup(props) {
    const parkingMarker = ref(props.parking);
    const type = ref(props.parking?.type);
    return { parkingMarker, type };
  },
});
